const data = {
    smallTitle: 'Solutions',
    title: 'CUCo in the Education sector',
    bgImage: {
        src: './images/education_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "Applicability in educational projects",
    description:"In educational environments, the safety of electronic devices is paramount in supporting effective teaching and learning. CUCo Security offers a multitude of unique features tailored to address the specific needs of educational projects, ensuring the safety and integrity of devices and data.",
}

export default data;