const data = {
    title: 'Request Demo',
    inputs:[
        {
            type:"text",
            placeholder:"Name",
            isRequired:true,
            name:"name"
        },
        {
            type:"text",
            placeholder:"Company",
            isRequired:false,
            name:"company"
        },
        {
            type:"text",
            placeholder:"Position",
            isRequired:false,
            name:"position"
        },
        {
            type:"text",
            placeholder:"Country",
            isRequired:false,
            name:"country"
        },
        {
            type:"number",
            placeholder:"Contact",
            isRequired:false,
            name:"contact"
        },
        {
            type:"email",
            placeholder:"Email",
            isRequired:true,
            name:"email"
        },
        {
            type:"textarea",
            placeholder:"Message",
            isRequired:false,
            name:"message"
        }
    ],
    terms: [
        {
            t:"I have read and agree to the "
        },
        {
            type:"privacy",
            t:"privacy policy"
        },
        {
            t:" and "
        },
        {
            type:"terms",
            t:"terms of use"
        },
        {
            t:"."
        }
    ],
    button: {
        text: "Submit"
    }    
}

export default data;