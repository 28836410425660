import React, { useState } from "react";

import Header from "../components/organisms/header";
import Intro from "../components/sections/intro";
import FaqsList from "../components/sections/faqsList";
import Footer from "../components/organisms/footer";

//data
import dataHeader from "../components/organisms/header/data";
import dataIntro from "../components/sections/intro/data_faqs";
import dataFaqs from "../components/sections/faqsList/data";
import dataFooter from "../components/organisms/footer/data";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function Faqs(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Intro {...dataIntro} mb24/>
            <FaqsList {...dataFaqs}/>
            <Footer {...dataFooter}/>
        </>
    )
}