import React from "react";

import Text from "../../atoms/text";


export default function StatisticsBox (props) {

    const {
        number,
        text
    } = props

    return (
        <div className="statisticsBox">
            { number && 
                <Text 
                    type="h2"
                    font="poppins-semibold" 
                    color="yellow"
                    fontSize="font-52" 
                    text={number}
                    maxLines='1'
                />
            }
            
            { text && 
                <Text 
                    type="p"
                    font="roboto-light" 
                    color="lightGrey"
                    fontSize="font-24"
                    text={text}
                    maxLines='3'
                />
            }           
        </div>
    )
}
