import React, { useState } from "react";

import Header from "../components/organisms/header";
import Intro from "../components/sections/intro";
import ListTextImage from "../components/sections/listTextImage";
import Footer from "../components/organisms/footer";

//data
import dataHeader from "../components/organisms/header/data";
import dataIntro from "../components/sections/intro/data_solutions_government";
import dataListTextImage from "../components/sections/listTextImage/data_solutions_government";
import dataFooter from "../components/organisms/footer/data";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function Government(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                darkMode
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Intro bg="light" {...dataIntro}/>
            <ListTextImage {...dataListTextImage}/>
            <Footer {...dataFooter}/>
        </>
    )
}