import React from "react";

import SliderCategories from "../../molecules/sliderCategories";
import Image from "../../atoms/image";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Container from "../../atoms/container";
import { Link } from "react-router-dom";


export default function categoriesList(props) {

    const {
        bg="dark",
        items,
        caseStudies,
        button
    } = props

    return (

        <section className={`bg-${bg} categoriesList margin-sectionTop margin-sectionBottom`}>
            {props.categories && props.categories.length > 0 &&
                <SliderCategories {...props} />
            }

            <Container size="md">
                {caseStudies ?
                    <div className="complexList margin-sectionBottom">
                        { items && items.length > 0 && items.map((item, index) => (
                            <Link to={item?.page} key={index}>
                                <div className="item" key={index}>
                                    <div className="image-container">
                                        { item.image && item.image.src &&
                                            <Image src={item.image.src} alt={item.image.alt} />
                                        }
                                        <div className="colorHover"></div>
                                        <div className="bottom-content">
                                            { item.category &&
                                                <div className="category-container">
                                                    <Text
                                                        type="span"
                                                        font="poppins-regular"
                                                        fontSize="font-32"
                                                        color="white"
                                                        text={item.category}
                                                        maxLines="2"
                                                    />
                                                </div>
                                            }
                                            <Button arrowRight/>                                        
                                        </div>
                                    </div>
                                    {item.title &&
                                        <Text
                                            type="h3"
                                            font="poppins-semibold"
                                            fontSize="font-40"
                                            color="white"
                                            text={item.title}
                                            maxLines="2"
                                        />
                                    }
                                    {item.description &&
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            fontSize="font-20"
                                            color="white"
                                            text={item.description}
                                            maxLines="3"
                                        />
                                    }

                                </div>
                            </Link>
                        ))}
                    </div>
                    :
                    <div className="simpleList margin-small-sectionBottom">
                        {items && items.length > 0 && items.map((item, index) => {
                            if (item.highlight) {
                                return (
                                    <a href={item?.file} key={index} target="_blank" rel="noopener noreferrer">
                                        <div className="item highlight" key={index}>                                            
                                            <div className="image-container">                                                                                             
                                                {item.image && item.image.src &&
                                                    <Image src={item.image.src} alt={item.image.alt} />
                                                }
                                                <div className="colorHover"></div>  
                                                <div className="bottom-content">
                                                    {item.category &&
                                                        <div className="category-container">
                                                            <Text
                                                                type="span"
                                                                font="poppins-regular"
                                                                fontSize="font-32"
                                                                color="white"
                                                                text={item.category}
                                                                maxLines="2"
                                                            />
                                                        </div>
                                                    }
                                                    <Button arrowRight/>                                                   
                                                </div>
                                            </div>
                                            {item.title &&
                                                <Text
                                                    type="h3"
                                                    font="poppins-semibold"
                                                    fontSize="font-40"
                                                    color="white"
                                                    text={item.title}
                                                    maxLines="2"
                                                />
                                            }
                                            {item.description &&
                                                <Text
                                                    type="span"
                                                    font="poppins-regular"
                                                    fontSize="font-20"
                                                    color="white"
                                                    text={item.description}
                                                    maxLines="3"
                                                />
                                            }

                                        </div>
                                    </a>
                                )
                            }
                        })}
                        <div className="seeMoreArea margin-small-sectionTop ">
                            <div className="options">
                                {items && items.length > 0 && items.map((item, index) => {
                                    if (!item.highlight) {
                                        return (
                                            <a href={item?.file} key={index} target="_blank" rel="noopener noreferrer">
                                                <div className="item" key={index}>
                                                    <div className="image-container">
                                                        {item.image && item.image.src &&
                                                            <Image src={item.image.src} alt={item.image.alt} />
                                                        }
                                                        <div className="colorHover"></div>  
                                                        <div className="bottom-content">
                                                            {item.category &&
                                                                <div className="category-container">
                                                                    <Text
                                                                        type="span"
                                                                        font="poppins-regular"
                                                                        fontSize="font-20"
                                                                        color="white"
                                                                        text={item.category}
                                                                        maxLines="2"
                                                                    />
                                                                </div>
                                                            }
                                                            <Button arrowRight page={item.page}/>                                                            
                                                        </div>
                                                    </div>
                                                    {item.title &&
                                                        <Text
                                                            type="h3"
                                                            font="poppins-semibold"
                                                            fontSize="font-30"
                                                            color="white"
                                                            text={item.title}
                                                            maxLines="2"
                                                        />
                                                    }
                                                    {item.description &&
                                                        <Text
                                                            type="span"
                                                            font="poppins-regular"
                                                            fontSize="font-18"
                                                            color="white"
                                                            text={item.description}
                                                            maxLines="3"
                                                        />
                                                    }

                                                </div>
                                            </a>
                                        )
                                    }
                                })}
                            </div>
                        </div> 
                        {button && button.text && button.page &&
                            <div className="button-container">
                                <Button type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={button.text} page={button.page} />
                            </div>
                        }
                    </div>

                }
            </Container>

        </section>

    )
}