import React, { useState } from "react";

import Container from "../../atoms/container";


export default function SliderCategories (props) {

    const {
        categories,
    } = props;

    const [active, setActive] = useState(-1);

    return (
        <div className="sliderCategories margin-small-sectionBottom">
            <Container size="md-right">
                {categories && categories.length > 0 &&
                    <div className={`item ${active === -1 && "active"}`} onClick={() => setActive(-1)}>
                        All
                    </div>
                }
                {categories && categories.length > 0 && categories.map ((item, index) => (
                    <div className={`item ${active === (index) && "active"}`} key={index} onClick={() => setActive(index)}>
                        {item.name}
                    </div>
                ))}
            </Container>
        </div>
    )
}