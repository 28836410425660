import React from "react";
import { ReactSVG } from "react-svg";

import Image from "../../atoms/image";
import Container from "../../atoms/container";
import Text from "../../atoms/text";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import Button from "../../atoms/button";
import { Link } from "react-router-dom";


export default function Banner(props) {

    const {
        info,
        slider
    } = props;

    return (
        <section className="banner margin-sectionBottom">
            {slider && slider.length > 0 &&
                <Swiper
                    loop={true}
                    autoplay={{
                        delay: 7000,
                        stopOnLastSlide: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }}
                    modules={[Pagination, Navigation, Autoplay]}
                >
                    {slider.map((item, index) => (
                        <SwiperSlide key={index}>
                            <Image src={item.backgroundImage.src} alt={item.backgroundImage.alt} className='backgroundImage' />
                            <div className="margin-firstSectionTop" />
                            <Container size="md">
                                <div className="text-container">
                                    <div>
                                        {item.title &&
                                            <Text
                                                type="h1"
                                                font="poppins-medium"
                                                color="white"
                                                text={item.title}
                                                maxLines="3"
                                                fontSize="font-71"
                                            />
                                        }
                                        {item.buttons && item.buttons.length > 0 &&
                                            <div className="buttons-container">
                                                {item.buttons[0] && item.buttons[0].text && item.buttons[0].page &&
                                                    <Button
                                                        type="fullColored bold"
                                                        bgcolor="bgYellow"
                                                        textcolor="textBlack"
                                                        text={item.buttons[0].text}
                                                        page={item.buttons[0].page}
                                                    />
                                                }
                                                {item.buttons[1] && item.buttons[1].text && item.buttons[1].page &&
                                                    <Button
                                                        type="lineColored bold"
                                                        bgcolor="bgYellow"
                                                        textcolor="textYellow"
                                                        text={item.buttons[1].text}
                                                        margin='mr16'
                                                        page={item.buttons[1].page}
                                                    />

                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="descriptionButtons-container">

                                        {item.description &&
                                            <Text
                                                className="description"
                                                type="p"
                                                font="roboto-light"
                                                color="white"
                                                text={item.description}
                                                maxLines="5"
                                                fontSize="font-22"
                                            />
                                        }
                                    </div>
                                </div>
                                {info && index === 0 &&
                                    <div className="info">
                                        {info.number &&
                                            <div>
                                                <Text
                                                    type="span"
                                                    font="roboto-medium"
                                                    color="white"
                                                    fontSize="font-52"
                                                    text={info.number}
                                                    maxLines="1"
                                                />
                                                {info.image && info.image.src &&
                                                    <Image src={info.image.src} alt={info.image.alt} className="iconHighlight" />
                                                }
                                            </div>
                                        }
                                        {info.text &&
                                            <Text
                                                type="span"
                                                font="roboto-light"
                                                color="white"
                                                fontSize="font-22"
                                                text={info.text}
                                                maxLines="1"
                                            />
                                        }
                                    </div>
                                }
                            </Container>
                        </SwiperSlide>
                    ))}
                    {slider.length >= 2 &&
                        <div className="swiper-buttons-container">
                            <div className="swiper-button-prev"> <ReactSVG src={"/images/buttonArrow.svg"} /></div>
                            <div className="swiper-button-next"> <ReactSVG src={"/images/buttonArrow.svg"} /></div>
                        </div>
                    }
                    <div className="mouse-icon-container">
                        <Image src="/images/seta_scrolldown.svg" alt="mouse" />
                    </div>
                </Swiper>
            }
        </section>
    );
}