import React from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Accordion from "../../molecules/accordion";
import Button from "../../atoms/button";
import { Link } from "react-router-dom";


export default function Introducing (props) {

    const {
        sectionTitle,
        imageTitle,
        image,
        imageLinks,
        description, 
        accordion,
        button
    } = props;

    return ( 

        <section className="introducing margin-sectionBottom margin-sectionTop">
            <Container size="sm">
                <div className="sectionTitle-container">
                    { sectionTitle &&
                        <Text
                            type="h2"
                            font="poppins-light" 
                            color="grey"
                            fontSize="font-40" 
                            text={sectionTitle} 
                            maxLines="2"
                        />
                    }
                </div>         
                <div className="image-container">        
                    {imageTitle &&
                        <Text 
                            className="image-title"
                            type="h2"
                            font="poppins-semibold" 
                            color="yellow"
                            fontSize="font-66" 
                            text={imageTitle}
                            maxLines="4"
                        />
                    }
                    {image && image.src &&
                        <div className="image">
                            <Image src={image.src} alt={image.alt} />  
                            {imageLinks &&
                                <div className="image-interactive-container">
                                    { imageLinks && imageLinks.linkTop &&
                                        <Link to={imageLinks.linkTop}>
                                            <div className="linkTop"></div>
                                        </Link>
                                    }
                                    { imageLinks && imageLinks.linkLeft &&
                                        <Link to={imageLinks.linkLeft}>
                                            <div className="linkLeft"></div>
                                        </Link>
                                    }
                                    { imageLinks && imageLinks.linkRight &&
                                       <Link to={imageLinks.linkRight}>
                                            <div className="linkRight"></div>
                                        </Link>
                                    }
                                </div>
                            }
                        </div>            
                    }
                 </div>
                {description &&
                    <Text 
                        className="description"
                        type="p"
                        font="poppins-regular" 
                        color="yellow"
                        fontSize="font-32" 
                        text={description}
                        maxLines="10"
                    />
                }
                {accordion && accordion.length > 0 &&
                <div className="accordion-container">
                    { accordion.map((item, index) => (
                        <Accordion key={index} {...item}/>
                    ))}
                </div>
                }
                {button && button.page &&
                    <div className="button-container">
                        <Button text={button.text} type="fullColored bold" bgcolor="bgYellow" textcolor="textBlack" page={button.page}/>
                    </div>
                }                
            </Container>
        </section>

    )
}