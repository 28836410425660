import React from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import { Link } from "react-router-dom";



export default function CaseStudyBody(props) {

    const {
        textArea,
        moreOptions
    } = props;



    return (
        <section className='caseStudyBody'>
            {textArea.length > 0 &&
                <div className="text-container margin-sectionBottom">
                    {textArea.map((item, index) => (
                        <div className={`block ${index === 0 ? 'margin-sectionTop' : ''}`} key={index}>
                            <div></div>
                            <Container size="md" >
                                {item.title &&
                                    <Text
                                        className="title"
                                        type="h3"
                                        font="poppins-medium"
                                        fontSize="font-36"
                                        color="yellow"
                                        text={item.title}
                                    />
                                }
                                {item.text &&
                                    <Text
                                        className="text"
                                        type="p"
                                        font="roboto-light"
                                        fontSize="font-30"
                                        color="grey"
                                        text={item.text}
                                    />
                                }
                                <div className={`images-container ${(item.imageOne && item.imageOne.src) && (item.imageTwo && item.imageTwo.src) ? 'double' : 'single'}`}>
                                    {((item.imageOne && item.imageOne.src) || (item.imageTwo && item.imageTwo.src)) &&
                                        <>
                                            {item.imageOne && item.imageOne.src &&
                                                <Image src={item.imageOne.src} alt={item.imageOne.alt} />
                                            }
                                            {item.imageTwo && item.imageTwo.src &&
                                                <Image src={item.imageTwo.src} alt={item.imageTwo.alt} />
                                            }
                                        </>
                                    }
                                </div>
                            </Container>
                        </div>
                    ))}
                </div>
            }


            {/* SEE MORE AREA */}
            <div className="seeMoreArea margin-sectionTop margin-sectionBottom">
                <Container size='md'>
                    {moreOptions.title &&
                        <Text
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-32"
                            color="black"
                            text={moreOptions.title}
                            maxLines="2"
                        />
                    }
                    <div className="options">
                        {moreOptions && moreOptions.items && moreOptions.items.length > 0 && moreOptions.items.map((item, index) => (
                            <Link to={item?.page} key={index}>
                                <div className="item" key={index}>
                                    <div className="image-container">                                        
                                        {item.image && item.image.src &&
                                            <Image src={item.image.src} alt={item.image.alt} />
                                        }
                                        <div className="colorHover"></div>
                                        <div className="bottom-content">
                                            {item.category &&
                                                <div className="category-container">
                                                    <Text
                                                        type="span"
                                                        font="poppins-regular"
                                                        fontSize="font-22"
                                                        color="white"
                                                        text={item.category}
                                                        maxLines="2"
                                                    />
                                                </div>
                                            }
                                            <Button arrowRight />
                                        
                                        </div>
                                    </div>
                                    {item.title &&
                                        <Text
                                            type="h3"
                                            font="poppins-semibold"
                                            fontSize="font-24"
                                            color="black"
                                            text={item.title}
                                            maxLines="2"
                                        />
                                    }
                                    {item.description &&
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            fontSize="font-13"
                                            color="black"
                                            text={item.description}
                                            maxLines="2"
                                        />
                                    }

                                </div>
                            </Link>
                        ))}
                    </div>
                    {moreOptions.button && moreOptions.button.text &&
                        <div className="button-container">
                            { moreOptions.button.page &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgYellow" 
                                    textcolor="textWhite poppins" 
                                    text={moreOptions.button.text} 
                                    page={moreOptions.button.page}
                                />
                            }
                             { moreOptions.button.link &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgYellow" 
                                    textcolor="textWhite poppins" 
                                    text={moreOptions.button.text} 
                                    link={moreOptions.button.link}
                                />
                            } 
                            { moreOptions.button.file &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgYellow" 
                                    textcolor="textWhite poppins" 
                                    text={moreOptions.button.text} 
                                    file={moreOptions.button.file}
                                />
                            }
                        </div>
                    }
                </Container>
            </div>

        </section>

    )
}