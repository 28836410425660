import React, {useState} from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";


export default function LegalDetail (props) {

    const {
        title,
        items
    } = props;

    const [itemOpen, setItemOpen] = useState(-1);

    return ( 

        <section className="legalDetail margin-sectionBottom margin-firstSectionTop">
            <Container size="md">
                { title &&
                    <Text 
                        type="h3"
                        font="poppins-medium"
                        fontSize="font-28"
                        color="yellow"
                        text={title}
                        maxLines="2"
                    />
                }
                <div className="pages-container">
                    <div className={`frontPage ${itemOpen !== -1 ? 'tabs' : ''}`}>
                        { items && items.length > 0 && items.map((item, index) => (
                            <button key={index} onClick={() => setItemOpen(index)}>
                                <Text 
                                    type="h2"
                                    font="poppins-medium"
                                    fontSize="font-36"
                                    color="black"
                                    text={item?.title}
                                />
                            </button>
                        ))}
                    </div>
                    {items && items.length > 0 && items.map((item, index) => (
                        <div className={`infoPage page-${index} ${itemOpen === index ? 'open' : ''}`}  key={index}>
                            <Text 
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-66"
                                color="black"
                                text={item?.title}
                                maxLines=""
                            />
                            <div className="description">
                                {item.text && item.text.map((text, tIndex) => (
                                    <div className="paragraph" key={tIndex}>
                                        {text.title &&
                                            <Text 
                                                type="h2"
                                                font="roboto-medium"
                                                fontSize="font-36"
                                                color="black"
                                                text={text.title}
                                            />
                                        }
                                        {text.description &&
                                            <Text 
                                                type="p"
                                                font="roboto-light"
                                                fontSize="font-24"
                                                color="black"
                                                text={text.description}
                                            />
                                        }
                                        {text.bullets && text.bullets.length > 0 &&
                                        <ul>
                                            { text.bullets.map((bullet, bIndex) => (
                                                <li key={bIndex}>
                                                    <Text 
                                                        type="span"
                                                        font="roboto-light"
                                                        fontSize="font-24"
                                                        color="black"
                                                        text={bullet?.text}
                                                    />  
                                                </li>                                        
                                            ))}
                                        </ul>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}    
                </div>        
            </Container>
        </section>

    )
}