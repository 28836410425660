import React from "react";



export default function Container (props) {

    const {
        size,
        children
    } = props;


    return (
        <div className={`container ${size}`}>
            {children}
        </div>
    )
}