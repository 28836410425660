const data = {
    title: 'Features checklist',
    leftItems: [
        {
            text: 'Remotely apply updates to the BIOS/UEFI across devices, ensuring they remain up to date with the latest security patches, stability improvements and hardware compatibility enhancements without the need for physical access'
        },
        {
            text: 'Remotely enable or disable the PXE boot option, facilitating network-based booting for tasks such as OS deployment, system recovery, or diagnostics, enhancing manageability in large or dispersed environments'
        },
        {
            text: 'Provide the ability to remotely restore BIOS settings to their default values, useful for resolving configuration issues, preparing devices for redeployment or resetting security settings to a known and stable state'
        },
        {
            text: 'Provide the ability to remotely add, modify or remove BIOS user access passwords, offering flexible management of security controls to align with organizational policies or specific security needs'
        },
    ],
    rightItems: [
        {
            text: 'Allow remote enabling or disabling of specific boot options in BIOS/UEFI settings, fine-tuning control over how devices boot, enhancing security by preventing unauthorized access while ensuring compliance with IT policies'
        },
        {
            text: 'Enable the retrieval of the current boot order configuration of a device, allowing for verification or troubleshooting of boot settings remotely, ensuring that the device boots from the correct source according to organizational policies'
        },
        {
            text: 'Enable remote activation or deactivation of the BIOS access password, securing BIOS settings from unauthorized changes and allowing controlled access, for troubleshooting and maintenance purposes'
        }
    ]
}

export default data;