import React from "react";

import Text from "../../atoms/text";
import Image from "../../atoms/image";
import Button from "../../atoms/button";


export default function BoxInfo(props) {

    const {
        title,
        description,
        image,
        button
    } = props

    return (
        <div className="boxInfo">
            <div>
            <div className="title-container">
                {title &&
                    <Text
                        type="h2"
                        font="poppins-semibold"
                        color="white"
                        fontSize="font-36"
                        text={title}
                        maxLines='3'
                    />
                }
                { button && button.page &&
                    <Button 
                        type="fullColored bold" 
                        bgcolor="bgDark" 
                        textcolor="textYellow" 
                        arrowRightYellow
                        page={button.page}
                    />
                }
            </div>

            {description &&
                <Text
                    type="p"
                    font="roboto-light"
                    color="lightGrey"
                    fontSize="font-18"
                    text={description}
                />
            }
            </div>
            {image && image.src && <Image src={image.src} alt={image.alt ? image.alt : 'product image'} />}
            <div className="button-container">
                { button && button.page &&
                    <Button 
                        type="fullColored bold" 
                        bgcolor="bgDark" 
                        textcolor="textYellow" 
                        arrowRightYellow
                        page={button.page}
                    />
                }
            </div>
        </div>
    )
}
