const data = {
    smallTitle: 'Why CUCo?',
    title: 'CUCo for Students',
    titleDescription:"CUCo Security Suite offers students many perks, facilitating the distribution and regulation of devices supplied by the school. With CUCo Security Suite students can feel safe by knowing that the risk of their device being stolen is minimal, as CUCo Security Suite’s ability to lock devices nullifies their value in the black market and stops unauthorized access of information.",
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
}

export default data;