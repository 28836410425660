const data = {
    info: {
        number:'+3 million',
        text: 'devices worldwide',
        image: {
            src: '',
            alt: ''
        }
    },    
    slider: [
       {
        title: 'Firmware based secure endpoint',
        buttons: [
            {
                text: '',
                page: ''
            },
            {
                text: 'Learn More',
                page: '/remote-lock'
            }
        ],
        description:"Integrating security and remote management features, CUCo Security Suite is the firmware-based solution capable of answering all your projects' safety needs",            
        backgroundImage: {
            src:'./images/banner-background.png',
            alt:'banner image'
        },
   },
   {
    title: 'CUCo Security Suite at GESS Dubai 2024',
    buttons: [
        {
            text: '',
            page: ''
        },
        {
            text: 'Learn More',
            page: '/news/gess'
        }
    ],
    description:"Join us at GESS Dubai 2024, where CUCo Firmware Security will be showcasing CUCo Security Suite from Novemeber 12th to 14th at the Dubai World Trade Center. We look forward to seeing you there!",            
    backgroundImage: {
        src:'./images/banner1.png',
        alt:'banner image'
    },
},
{
    title: 'CUCo Security Suite at Bett UK 2025',
    buttons: [
        {
            text: '',
            page: ''
        },
        {
            text: 'Learn More',
            page: '/news/bett'
        }
    ],
    description:"CUCo Security Suite showcased at Bett UK 2025 from the 22nd to the 24th of January.",            
    backgroundImage: {
        src:'./images/banner2.png',
        alt:'banner image'
    },
},
    ]    
}

export default data;