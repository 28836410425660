import { useState } from "react";
import { useForm } from "react-hook-form";
import Turnstile, { useTurnstile } from "react-turnstile";
import { Link } from "react-router-dom";

import Text from "../../atoms/text";
import { ReactSVG } from "react-svg";



export default function Modal(props) {

    const {
        isOpen,
        title,
        inputs,
        terms,
        button,
        setIsModalOpen,
    } = props

    const turnstile = useTurnstile();

    const [token, setToken] = useState();
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async formData => {
        try {
            const payload = { data: formData, turnstile_response: token };
            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            var requestOptions = {
                method: 'POST',
                mode: 'cors',
                headers: myHeaders,
                body: JSON.stringify(payload)
            };
            fetch('https://erpmiddleware.cuco-firmware.com/', requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
            alert("Message submitted successfully!")
            reset()
            setIsModalOpen(false)
        } catch (errors) {
            console.error('Error:', errors);
        }
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div>
                {title &&
                    <div className="title-container">
                        <Text
                            type="span"
                            font="poppins-medium"
                            color="white"
                            text={title}
                            fontSize="font-52"
                        />
                    </div>
                }
                <div className="closeButton" onClick={() => setIsModalOpen(false)}>
                    <ReactSVG src="images/close-button.svg" />
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {inputs.map(({ type, placeholder, isRequired, name }) => {

                        return (
                            <div key={name}>
                                {type === 'textarea' ? (
                                    <textarea
                                        key={name}
                                        placeholder={placeholder}
                                        {...register(name, { required: isRequired })}
                                    />
                                ) : (
                                    <input
                                        key={name}
                                        type={type}
                                        placeholder={placeholder}
                                        {...register(name, { required: isRequired })}
                                    />

                                )}
                                {errors[name] && isRequired && (
                                    <span style={{ color: 'red' }}>This field is required</span>
                                )}
                            </div>
                        )
                    })}
                    {
                        isOpen ?
                            <Turnstile
                                theme="dark"
                                refreshExpired="auto"
                                sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                                onVerify={(token) => {
                                    setToken(token)
                                }}
                            />
                            :
                            <></>
                    }
                    <div className="bottom-div">
                        <div className="termsofservice">
                            <label className="container">
                                <input type="checkbox" name="terms" required />
                                <span className="checkmark"></span>
                            </label>
                            <Text
                                type="span"
                                font="poppins-regular"
                                color="lightGrey"
                                text={terms.map((item, index) => {
                                    if (item.type === "privacy") {
                                        return (
                                            <Link key={index} to="/legal">
                                                {item.t}
                                            </Link>
                                        )
                                    }
                                    if (item.type === "terms") {
                                        return (
                                            <Link key={index} to="/legal">
                                                {item.t}
                                            </Link>
                                        )
                                    }

                                    return <>{item.t}</>
                                })}
                                fontSize="font-12"
                            />
                        </div>
                        <input type="submit" value={button?.text} />
                    </div>
                </form>
            </div>
        </div>
    )
}
