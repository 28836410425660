const data = {
    title: "Legal",
    items: [
        {
            title: "Disclaimers",
            text: [
                {
                    title: "No-Liability Disclaimer",
                    description: "The information contained on this website does not, and is not intended to, constitute legal advice. Instead, all information, content, and materials available on this website are for general information purposes only. This website contains third-party links, included for the convenience of the user. CUCo Firmware Security does not recommend and is not responsible for the contents of the third-party websites."
                },
                {
                    title: "Testimonial Disclaimer",
                    description: "Testimonials presented on this website are received through direct contact with their authors. They are personal experiences that reflect the authors’ experience with our products or services. Thus, the results of this experiences are individual and may vary. The testimonials included in this website do not guarantee that the user may find the same results with our products or services as the author. Testimonials are presented verbatim, except for the correction of grammatical or typing errors, and with the expressed consent of their authors. Some of these testimonials may have been shortened. CUCo Firmware Security is not a testimonials forum, however we do provide testimonials with the goal of sharing experiences from one user to another. CUCo Firmware Security does not share the opinions, views or commentary of any testimonial on this website."
                }
            ]
        },
        {
            title: "Privacy Policy",
            text: [
                {
                    description: "Our Privacy Policy was last updated on 2024/10/29"
                },
                {
                    description: "Softi9 – Inovação Informática, Lda. (from this point onwards referred to as Softi9) on the behalf of CUCo Firmware Security, Lda. promotes the responsible processing of the user’s personal data, complying with the principles and regulations established by the EU General Data Protection Regulation (GDPR)."
                },
                {
                    title: "What data do we collect",
                    description: "CUCo Firmware collects the following data:",
                    bullets: [
                        {
                            text: "Browser information",
                        },
                        {
                            text: "Device brand model, screen resolution and approximate connection",
                        },
                        {
                            text: "Operating system version",
                        },
                        {
                            text: "Platform version",
                        }
                    ]
                },
                {
                    description: "Additionally, Softi9 may also receive the user’s data indirectly from the following sources:",
                    bullets: [
                        {
                            text: "Google Analytics"
                        }
                    ]
                },
                {
                    title: "How will we use the user’s data?",
                    description: "Softi9 collects the user’s data for the following purposes:",
                    bullets: [
                        {
                            text: "Business leads creation"
                        },
                        {
                            text: "Better understanding of its clients and their use of its website"
                        }
                    ]
                },
                {
                    title: "How do we store the user’s data?",
                    description: "Softi9 stores the user’s data in a private, self-owned server, encrypted and protected by advanced security mechanisms. Softi9 will keep the user’s data for 2 years. Once this time period has expired, all data will be deleted from our databases."
                },
                {
                    title: "Marketing",
                    description: "Softi9 would like to send the user information about our products and services.If the user has agreed to receive marketing, they may always opt out at a later date. The user has the right to, at any moment, stop Softi9 from contacting them for marketing purposes. If the user no longer wishes to be contacted, they may contact us, via email, at gdpr-support@cuco-firmware.com "
                },
                {
                    title: "What are your data protection rights?",
                    description: "Softi9 would like to make the user fully aware of all their data protection rights. Every user is entitled to the following:",
                    bullets: [
                        {
                            text: "Right to access: The user has the right to request Softi9 for copies of all their personal data."
                        },
                        {
                            text: "Right to rectification: The user has the right to request Softi9 to correct any information they believe is inaccurate. The user also has the right to request Softi9 to complete information they believe is incomplete."
                        },
                        {
                            text: "Right to erasure: The user has the right to request Softi9 to erase their personal data, under certain conditions, defined in Art. 17 of the EU GDPR."
                        },
                        {
                            text: "Right to restrict processing: The user has the right to request Softi9 to restrict the processing of their personal data, under certain conditions defined in Art. 18 of the EU GDPR."
                        },
                        {
                            text: "Right to object processing: The user has the right to object to Softi9’s processing of their data, under certain conditions, defined in Art. 19 of the EU GDPR."
                        },
                        {
                            text: "Right to data portability: The user has the right to request Softi9 to transfer the user’s data to another organization, or directly to them, under certain conditions, defined in Art. 20 of the EU GDPR."
                        }
                    ]
                },
                {
                    description: "Upon request, Softi9 has one month to respond to the user. If the user would like to exercise any of these rights, please contact us: gdpr-support@cuco-firmware.com"
                },
                {
                    title: "What are cookies?",
                    description: "Cookies are text files placed on the user’s computer to collect standard Internet log information and visitor behavior information. When the user visits our websites, it may collect information automatically through cookies or similar technology."
                },
                {
                    title: "How do we use cookies?",
                    description: "Softi9 uses cookies in a range of ways to improve the user’s experience on our website, including:",
                    bullets: [
                        {
                            text: "Keeping the user signed in"
                        },
                        {
                            text: "Understanding how the user utilizes the website"
                        }
                    ]
                },
                {
                    title: "What types of cookies do we use?",
                    bullets: [
                        {
                            text: "Functionality - Softi9 uses these cookies to recognize the user on our website and remember their previously selected preferences. These could include what language they prefer and the location they are in. A mix of first-party and third-party cookies are used."
                        },
                        {
                            text: "Advertising - Softi9 uses these cookies to collect information about the user's visit to our website, the content they viewed, the links they followed and information about their browser, device and IP address. Softi9 sometimes shares some limited aspects of this data with third parties for advertising purposes."
                        }
                    ]
                },
                {
                    title: "How to manage cookies",
                    description: "The user can set their browser to not accept cookies or retroactively remove cookies from their browser."
                },
                {
                    title: "Privacy policies of other websites",
                    description: "Softi9 contains links to other websites. Softi9’s privacy policy only applies to our website."
                },
                {
                    title: "Changes to our privacy policy",
                    description: "Softi9 keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 2024/10/29"
                },
                {
                    title: "How to contact us",
                    description: "If the user has any questions about Softi9’s privacy policy, the data we hold, or if they would like to exercise one of their data protection rights, they shouldn’t hesitate in contacting us. E-mail: gdpr-support@cuco-firmware.com"
                },
                {
                    title:"How to contact the appropriate authority",
                    description:"Should the user wish to report a complaint or if they feel that Softi9 has not addressed their concern in a satisfactory manner, they may contact CNPD. Email: geral@cnpd.pt Address: Avenida D. Carlos 1, 134, 1º, 1200-561 Lisboa"
                }


            ]
        },
        {
            title: "Terms and Conditions",
            text: [
                {
                    description: "Please read these terms and conditions ('terms and conditions', 'terms') carefully before using cuco-firmware.com website ('website', 'service') operated by Softi9 - Inovação Informática, Lda. (hereinafter referred to as Softi9), on the behalf of CUCo Firmware Security, Lda. ('us«, 'we', 'our')."
                },
                {
                    title: "Conditions of use",
                    description: "By using this website, you certify that you have read and reviewed this Agreement and that you agree to comply with its terms. If you do not want to be bound by the terms of this Agreement, you are advised to stop using the website accordingly. Softi9 only grants use and access of this website, its products, and its services to those who have accepted its terms."
                },
                {
                    title: "Privacy policy",
                    description: "Before you continue using our website, we advise you to read our privacy policy and our disclaimers regarding our user data collection. "
                },
                {
                    title: "Age restriction",
                    description: "You must be at least 18 (eighteen) years of age before you can use this website. By using this website, you warrant that you are at least 18 years of age, and you may legally adhere to this Agreement. In case you are under 18 years of age but are at least 13 years old (a “minor”), you acknowledge and agree that you are using this website with the consent of your parent or legal guardian. Softi9 assumes no responsibility for liabilities related to age misrepresentation. "
                },
                {
                    title:"Intellectual property",
                    description:"You agree that all materials, products, and services provided on this website are the property of Softi9, its affiliates, directors, officers, employees, agents, suppliers, or licensors including all copyrights, trade secrets, trademarks, patents, and other intellectual property. You also agree that you will not reproduce or redistribute the Softi9’s intellectual property in any way, including electronic, digital, or new trademark registrations. You grant Softi9 a royalty-free and non-exclusive license to display, use, copy, transmit, and broadcast the content you upload and publish. For issues regarding intellectual property claims, you should contact the company in order to come to an agreement."
                },
                {
                    title:"User accounts",
                    description:"As a user of this website, you may be asked to register with us and provide private information. You are responsible for ensuring the accuracy of this information, and you are responsible for maintaining the safety and security of your identifying information. You are also responsible for all activities that occur under your account or password. If you think there are any possible issues regarding the security of your account on the website, inform us immediately so we may address them accordingly. We reserve all rights to terminate accounts, edit or remove content and cancel orders at our sole discretion."
                },
                {
                    title:"Applicable law",
                    description:"By using this website, you agree that the legislation enforced in Portugal, without regard to principles of conflict laws, will govern these terms and conditions, or any dispute of any sort that might come between Softi9 and you, or its business partners and associates."
                },
                {
                    title:"Limitation on liability",
                    description:"Softi9 is not liable for any damages that may occur to you as a result of your misuse of our website. Sofit9 reserves the right to edit, modify, and change this Agreement at any time. We shall let our users know of these changes through. This Agreement is an understanding between Softi9 and the user, and this supersedes and replaces all prior agreements regarding the use of this website."
                }
            ]
        },
    ]
}

export default data;