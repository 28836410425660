const data = {
    items: [
        {
            title:"Protect sensitive data",
            description:"CUCo Security Suite’s remote lock functionality allows for governmental agencies to feel safe, knowing that if any device is stolen, it can be immediately locked. These functionalities work at the firmware level, guaranteeing that all security measures are enforced, independently of internet connection.",
            image: {
                src:"./images/governmental_1.png",
                alt:"solution"
            }
        }, 
        {
            title:"Technological park management",
            description:"CUCo Security Suite enables administrators to manage software and hardware inventory across all the devices connected, ensuring their integrity.  These tasks are audited, safeguarding administrators and their agency.",
            image: {
                src:"./images/governmental_2.png",
                alt:"solution"
            }
        }, 
        {
            title:"Mass BIOS Updates",
            description:"CUCo Security Suite allows for simultaneous mass updates and access to the BIOS remotely, ensuring all devices benefit from the latest security patches, furthering their safety.",
            image: {
                src:"./images/governmental_3.png",
                alt:"solution"
            }
        }
    ],
    button: {
        text: "Case Studies",
        page:"/case-studies"
    }
}

export default data;