const data = {
    logo: {
        src: './images/logo-small.svg',
        alt: 'Cuco'
    },
    links: [
        {
            text: 'Projects and Funding',
            page: '/projects'
        },
        {
            text: 'Legal',
            page: '/legal'
        }
    ],
    copyright: '© Copyright 2024. Softi9',
    socialMedia: [        
        {
            link: 'https://linkedin.com/company/softi9-portugal',
            image: {
                src: './images/linkedin.svg',
            }
        }
    ],
    images: [
        {
            src:'./images/logos_apoios.png'
        }
    ]

}

export default data;