const data = {
    slider: [
        {
            image: {
                src: './images/un-aveiro.svg',
                alt: 'universidade aveiro'
            },
            text: '"The development of CUCo proved to be a great challenge, as no one had ever conceived a security solution working ate the level of the device´s boot"',
            author: 'André Zuquete',
            position: 'Associate Professor in the Dep. of Electronics, Telecommunications and Informatics of the University of Aveiro and researcher at IEETA'
        },
        {
            image: {
                src: './images/logo_inforlandia.svg',
                alt: 'inforlandia'
            },
            text: '“As an integrator, CUCo Security Suite has set my business proposals apart from my competitors, empowering me in providing a highly effective security solution fit for the demands of today’s digital education landscape.”',
            author: 'Valentim Costa',
            position: 'Client Manager'
        }
    ]

    
}

export default data;