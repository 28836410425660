const data = {
    categories: [
        /* {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },
        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },

        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        }, */    
    ],
    items: [
        {
            category:'Education',
            image: {
                src: "./images/CUCoinducation_thumbnail.png",
                alt: "image"
            },
            title: 'CUCo Security Suite in Education',
            description: 'Safeguarding students in the "Escola Digital" project',
            page:"/case-studies/escoladigital"
        },
        {
            category:'Education',
            image: {
                src: "./images/remote_lock_thumbnail.png",
                alt: "image"
            },
            title: 'Case Study: CUCo Remote Lock Pilot Project',
            description: 'Enabling DaaS projects',
            page:'/case-studies/pilot'
        },

    ]

}

export default data;