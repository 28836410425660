const data = {
    smallTitle: 'Why CUCo?',
    title: 'CUCo for OEM/integrators',
    titleDescription:"The addition of CUCo Security Suite in projects where device security is paramount allows for equipment manufacturers and integrators alike to distinguish themselves from their competitors.",
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
}

export default data;