import React from "react";


import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Image from "../../atoms/image";
import StatisticsBox from "../../molecules/statisticsBox";


export default function Intro (props) {

    const {
        bg = "dark",
        smallTitle,
        title,
        bgImage,
        smallHeight,
        titleDescription,
        descriptionBold,
        description,
        button,
        image,
        infoNumbers,
        mb24,
        setIsModalOpen,
        marginTitle
    } = props

    return ( 

        <section className={`intro bg-${bg}  ${mb24 ? "" : "margin-sectionBottom"}`}>
            
            <div className={`title-container ${bgImage && bgImage.src  ? 'bgImage' : ''} ${smallHeight ? 'smallHeight' : ''} margin-firstSectionTop`}>
                {bgImage && bgImage.src &&
                    <Image src={bgImage.src} alt={bgImage.alt}/>
                }
                <Container size="md">
                    <div className="text-container">
                        {smallTitle &&
                        <Text 
                            type="h3"
                            font="poppins-medium"
                            fontSize="font-28"
                            color="yellow"
                            text={smallTitle}
                            maxLines="2"
                        />
                        }
                        { title &&
                        <Text 
                            className={`title ${marginTitle ? 'mb' : ''}`}
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-66"
                            color={`${titleDescription ? "white mb24" : "white"}`}
                            text={title}
                            maxLines="3"
                        />
                        }
                        { titleDescription &&
                        <Text 
                            type="p"
                            font="poppins-light"
                            fontSize="font-36"
                            color="lightGrey"
                            text={titleDescription}
                            maxLines="10"
                        />
                        }
                    </div>
                </Container>
            </div>
            {(descriptionBold || description || button) &&
                <div className="description-container">
                    <Container size="md">
                        { descriptionBold &&
                        <Text 
                            type="p"
                            font="poppins-medium"
                            fontSize="font-36"
                            color="yellow"
                            text={descriptionBold}
                            maxLines="10"
                        />
                        }
                        { description &&
                        <Text 
                            className="description"
                            type="p"
                            font="roboto-light"
                            fontSize="font-30"
                            color="lightGrey"
                            text={description}
                            maxLines="10"
                        />
                        }
                    
                        { button && button.text &&
                            <Button 
                                type="fullColored" 
                                bgcolor="bgYellow" 
                                textcolor="textWhite" 
                                text={button.text} 
                                onClick={() => setIsModalOpen(true)}
                            />
                        }
                    </Container>
                </div>
            }
            <Container size="md">
        
                { image && image.src &&
                    <div className="image-container">
                        <Image src={image.src} alt={image.alt} className="image"/>  
                    </div>                  
                }
                { infoNumbers && infoNumbers.length > 0 && 
                    <div className="infoNumbers-container">
                        { infoNumbers.map ((item, index) => (
                        <StatisticsBox key={index} {...item}/>
                    ))}
                    </div>
                }
            </Container>
        </section>

    )
}