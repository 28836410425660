import React, { useState } from "react";

import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import CaseStudyBanner from "../components/sections/caseStudyBanner";
import CaseStudyBody from "../components/sections/caseStudyBody";

//data
import dataHeader from "../components/organisms/header/data";
import dataFooter from "../components/organisms/footer/data";
import dataCaseStudy from "../components/sections/caseStudyBanner/data";

// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";

export default function CaseStudiesDetail(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <CaseStudyBanner {...dataCaseStudy}/>
            <CaseStudyBody {...dataCaseStudy}/>
            <Footer {...dataFooter}/>
        </>
    )
}