const data = {
    newContent: {
        smallTitle:'News',
        title:"CUCo Security Suite at GESS Dubai 2024",
        description:"Join us at the 2024 GESS Dubai, where CUCo Firmware Security will be showcasing the CUCo Security Suite from November 12th to 14th at the Dubai World Trade Center. We look forward to seeing you there!",
        date:"2024.05.28",
        image: {
            src:"./images/news_gess.png",
            alt:"news image",
        },
        text: [
            {
                paragraph: "CUCo Security Suite will be showcased at GESS Dubai 2024, the leading educational convention and exhibition in the Middle East, seizing this opportunity to enter its new features into the spotlight, namely those regarding remote BIOS management and endpoint security and management.",
                bold: false
            },
            {
                paragraph: "GESS Dubai 2024 will count with the presence of companies and institutions from 74 different countries, totaling around 8000 professionals from the education sector. This year’s convention will focus on EdTech and the integration of technologies, specifically AR (Augmented Reality) and AI (Artificial Intelligence) in education.",
                bold: false
            },
            {
                paragraph: "Feel free to visit us at stand P51.",
                bold: false
            },
        ]
    }, 
    button: {
        text: "All articles",
        page: "/news"
    }   
}


export default data;