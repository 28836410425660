const data = {
    items: [
        {
            title:"Firmware based Security",
            description:"One standout feature of CUCo Security Suite is its firmware-based security model, providing robust protection from the firmware level up. This approach ensures comprehensive security beyond simple theft prevention, allowing for the implementation of customized security policies tailored to the requirements of both schools and students.",
            image: {
                src:"./images/education_1.png",
                alt:"solution"
            }
        }, 
        {
            title:"Operating System Agnosticism",
            description:"CUCo Security Suite is compatible with various operating systems commonly found in educational settings, such as Windows, Linux and Android. This versatility enables seamless integration with existing device environments, simplifying deployment and management processes for educational institutions.",
            image: {
                src:"./images/education_2.png",
                alt:"solution"
            }
        }, 
        {
            title:"RMM and MDM Functionalities",
            description:"CUCo Security Suite excels in remote device management, facilitating seamless firmware updates, remote diagnostics and device locking functionalities. This enables educators to efficiently monitor and maintain devices across classrooms and campuses, ensuring uninterrupted learning experiences.",
            image: {
                src:"./images/education_3.png",
                alt:"solution"
            }
        },
        {
            title:"Device Safety",
            description:"CUCo Security Suite's integration with advanced security features, including firmware-level malware protection and real-time threat detection, reinforces its position as a comprehensive security solution for educational environments. These capabilities not only protect devices from security threats but also safeguard student privacy and data integrity.",
            image: {
                src:"./images/education_4.png",
                alt:"solution"
            }
        }
    ],
    button: {
        text: "Case Studies",
        page:"/case-studies"
    }
}

export default data;