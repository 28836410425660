import React, { useState } from "react";

import Header from "../components/organisms/header";
import Section404 from "../components/sections/section404";

//data
import dataHeader from "../components/organisms/header/data";
import data404 from "../components/sections/section404/data"

// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function ErrorPage(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Header 
                {...dataHeader}  
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Section404 {...data404}/>
        </>
    )
}