import React, { useState } from "react";

import Header from "../components/organisms/header";
import Footer from "../components/organisms/footer";
import NewsBody from "../components/sections/newsBody";

//data
import dataHeader from "../components/organisms/header/data";
import dataFooter from "../components/organisms/footer/data";
import dataNew from "../components/sections/newsBody/data_bett";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";

export default function NewsDetail(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
        <Header 
            {...dataHeader} 
            formModal={dataModal} 
            isModalOpen={isModalOpen} 
            setIsModalOpen={setIsModalOpen}
        />
        <NewsBody {...dataNew}/>
        <Footer {...dataFooter}/>
        </>
    )
}