import React from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";
import BoxInfo from "../../molecules/boxInfo";


export default function Products (props) {

    const {
        sectionTitle,
        products
    } = props;

    return ( 

        <section className="products margin-sectionBottom margin-sectionTop">
            <Container size="md">
                <div className="sectionTitle-container margin-sectionTitle">
                    { sectionTitle &&
                        <Text 
                            type="h2"
                            font="poppins-light" 
                            color="grey"
                            fontSize="font-40" 
                            text={sectionTitle} 
                            maxLines="2"
                        />
                    }
                </div>
                {products && products.length > 0 &&
                <div className="products-list">
                    {products.map((item, index) => (
                        <BoxInfo key={index} {...item}/>
                    ))}              
                </div>
                }
            </Container>
        </section>

    )
}