const data = {
  banner: {
    image: {
        src:"./images/remote_lock.png",
        alt:"study case image",
    },
    title: 'Case Study: CUCo Remote Lock Pilot Project',
    subtitle:'Enabling DaaS projects',
    category: 'Education',
  },
  /* info: [
    {
        number:'3.300',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'5.000+',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'32%',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'2200',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
  ], */
  textArea: [
    {
      title: 'Introduction',
      text: 'A pilot project led by a private computer manufacturer, was launched in a college, to test the effectiveness of CUCo Remote Lock as a contract enforcement and theft deterrence system. Several thousand laptops, each valued at €500, were made available to low-income students for €149 each, along with a service contract of €14.9 per month for 2 years. No credit check was required; students simply had to sign the contract and pay the initial amount via direct debit from the bank account specified in the contract.',
    },
    {
      title: 'Problem Statement',
      text: 'Previously, Device as a Service (DaaS) projects have faced many challenges, regarding contract enforcement, resource management and asset tracking. Therefore, businesses and projects of this nature have been deemed as unviable, most of the time.',
      imageOne: {
          src:"./images/remote_lock1.png",
          alt:"study case image",
      },
      imageTwo: {
          src:"./images/remote_lock2.png",
          alt:"study case image",
      }
    },
    {
      title: 'Analysis',
      text: 'In this context, CUCo Security Suite is a true enabler of the DaaS business model, providing robust security, ensuring compliance with service contracts and protecting against theft and unauthorized access. By locking non compliant devices and tracking lost or stolen units, CUCo minimizes financial losses and enhances the feasibility of large-scale deployment projects. This comprehensive approach makes CUCo an invaluable asset in maintaining device integrity and ensuring the success of initiatives that rely on the widespread distribution of technology.',
      imageOne: {
          src:"./images/remote_lock3.png",
          alt:"study case image",
      },
    },
    {
      title: 'Outcome',
      text: "After running the program for over 2 years, statistical data showed that 27.9% of users stopped paying the monthly installments at some point. CUCo Firmware Security detected noncompliance and automatically locked the devices. Following the customized “lost-and-found/locked” message, 98.6% of users contacted the call center, paid the overdue installments, and received a one-time code to unlock and reactivate their devices. CUCo significantly reduced the number of default payments by locking noncompliant units. Additionally, it was found that 3% of the units had been offline for more than 2 months. CUCo's internal rules locked these devices even without network connectivity. During this period, 2% of the units were reported by users as stolen or lost. However, due to their “digital footprint” when connecting to the CUCo server, most of these units were recovered and either returned to the user or reported to the authorities. At the end of the contract period, all compliant units were freed from CUCo control. Without CUCo, the majority of the defaulted installments would have been lost, and the cost of recovery through traditional recovery agents or the legal system would have been too high. CUCo Firmware Security saved more than 20% of the sales price per unit. Without the CUCo solution, large deployment projects based on future financial streams would not be viable.",
    },
    {
      title: 'Conclusion',
      text: 'The implementation of CUCo in this project was deemed successful and further confirmed its capacity to improve and enable DaaS projects. Comparing data from past DaaS projects with the CUCo Security Suite initiative, we were able to verify its ability to enforce contracts, deter theft and protect the investments made by the device provider.',
    },
    
  ],
  moreOptions: {
    title: "",
    items: [
      {
          category:'Education',
          image: {
              src: "./images/students_2.png",
              alt: "image"
          },
          title: 'CUCo Security Suite in Education',
          description: 'Safeguarding Students in the “Escola Digital” project',
          page:'/case-studies/escoladigital'
      }
    ],
    button : {
      text: 'See All',
      page: '/case-studies'
    }
  }        
}


export default data;