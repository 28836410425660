const data = {
    sectionTitle: 'Our Products',
    products: [
        {
            title: "Remote Lock",
            description: "Automatically or manually lock and unlock devices through adaptable parameters. Running compliance checks before the boot of the operating system, CUCo Remote Lock ensures asset protection.",
            image: {
                src:"/images/lock.svg", 
                alt:'lock'
            },
            button: {
                page:"/remote-lock"
            } 
        },
        {
            title: "Secure Endpoint",
            description: "Remotely monitor, manage and secure endpoints. CUCo Secure Endpoint offers geo-localization services, Remote Monitoring and Management and Mobile Device Management functionalities.",
            image: {
                src:"/images/endpoint.svg", 
                alt:'endpoint'
            },
            button: {
                page:"/secure-endpoint"
            }
        },
        {
            title: "Remote BIOS Management",
            description: "Combining remote UEFI mass management with BIOS-level security all in a user-friendly UI, CUCo Remote BIOS Management reduces workload and enhances device safety.",
            image: {
                src:"/images/bios.svg", 
                alt:'bios'
            },
            button: {
                page:"/bios-management"
            }
        }
    ]
   
}

export default data;