const data = {
    smallTitle: 'Products',
    title: 'CUCo Remote Lock',
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "CUCo Remote Lock is a robust security module designed to enhance device protection and prevent unauthorized access.",
    description:"Being the first of its kind to operate at the firmware level, CUCo Remote lock allows organizations to enforce self-compliance checks on devices based on customizable parameters, such as number of boots, number of days without being used, etc. CUCo Remote Lock enforces security protocols before the boot of the Operating System, independently of internet connection, acting as a theft deterrent by enabling a non-destructive pre-boot lock, safeguarding both the user and their sensitive data.",
    button: {
        text: 'Request Demo'
    },
    /* image: {
        src:'./images/infografia_exp.png',
        alt:'info'
    }, */
    /* infoNumbers: [
        {
            number: "3.300",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "5.000+",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "32%",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "2.200",
            text: "Lorem ipsum dolor sit amet"
        },
    ] */
}

export default data;