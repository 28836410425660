import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./pages/homepage";
import ProductRemote from "./pages/product_remote";
import ProductBios from "./pages/product_bios";
//import Solutions from "./pages/solutions";
import CaseStudies from "./pages/caseStudies";
import News from "./pages/news";
import NewsDetail from "./pages/news_detail";
import NewsDetailBett from "./pages/news_detail_bett";
import Faqs from "./pages/faqs";
import ErrorPage from "./pages/errorPage";
import WhyCuco_admin from "./pages/whyCuco_administrators";
import WhyCuco_oem from "./pages/whyCuco_oem";
import WhyCuco_students from "./pages/whyCuco_students";
import WhyCuco_teachers from "./pages/whyCuco_teachers";
import WhyCuco_telecom from "./pages/whyCuco_telecom";
import ProductSecure from "./pages/product_secure";
import Education from "./pages/solutions_education";
import Corporate from "./pages/solutions_corporate";
import Government from "./pages/solutions_government";
import ScrollToTop from "./ToTheTop";
import CaseStudiesDetailEscola from "./pages/caseStudies_detail_escoladigital";
import CaseStudiesDetailPilot from "./pages/caseStudies_detail_pilot";
import CaseStudiesDetail from "./pages/caseStudies_detail";
import Catalogue from "./pages/catalogue";
import Legal from "./pages/legal";
import ProjectsAndSupports from "./pages/projectsandsupports";

export default function App() {
  return (
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='*' element={<ErrorPage/>} />
          <Route path="/" element={<Homepage />}></Route>
          <Route path="/remote-lock" element={<ProductRemote />}></Route>
          <Route path="/bios-management" element={<ProductBios />}></Route>
          <Route path="/secure-endpoint" element={<ProductSecure />}></Route>
          <Route path="/education" element={<Education />}></Route>
          <Route path="/corporate" element={<Corporate />}></Route>
          <Route path="/government" element={<Government />}></Route>
          <Route path="/case-studies" element={<CaseStudies />}></Route>
          <Route path="/case-studies-detail" element={<CaseStudiesDetail />}></Route>
          <Route path="/case-studies/escoladigital" element={<CaseStudiesDetailEscola />}></Route>
          <Route path="/case-studies/pilot" element={<CaseStudiesDetailPilot />}></Route>
          <Route path="/catalogue" element={<Catalogue />}></Route>
          <Route path="/news" element={<News />}></Route>
          <Route path="/news/gess" element={<NewsDetail />}></Route>
          <Route path="/news/bett" element={<NewsDetailBett />}></Route>
          <Route path="/faqs" element={<Faqs />}></Route>
          <Route path="/why-cuco/admin" element={<WhyCuco_admin />}></Route>
          <Route path="/why-cuco/oem" element={<WhyCuco_oem />}></Route>
          <Route path="/why-cuco/students" element={<WhyCuco_students />}></Route>
          <Route path="/why-cuco/teachers" element={<WhyCuco_teachers />}></Route>
          <Route path="/why-cuco/telecom" element={<WhyCuco_telecom />}></Route>
          <Route path="/legal" element={<Legal/>}></Route>
          <Route path="/projects" element={<ProjectsAndSupports/>} ></Route>
        </Routes>
      </Router>
  );
}


