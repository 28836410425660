import React, { useState } from "react";

import Header from "../components/organisms/header";
import Intro from "../components/sections/intro";
import Checklist from "../components/sections/checklist";
import Footer from "../components/organisms/footer";
import TextButtons from "../components/sections/textButtons";

//data
import dataHeader from "../components/organisms/header/data";
import dataIntro from "../components/sections/intro/data_products_remote";
import dataChecklist from "../components/sections/checklist/data_remote";
import dataTextButtons from "../components/sections/textButtons/data";
import dataFooter from "../components/organisms/footer/data";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function ProductRemote(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Intro 
                {...dataIntro}
                smallHeight
                setIsModalOpen={setIsModalOpen}
                marginTitle
            />
            <Checklist {...dataChecklist}/>
            {/* <TextButtons 
                {...dataTextButtons}
                setIsModalOpen={setIsModalOpen}
            /> */}
            <Footer {...dataFooter}/>
        </>
    )
}