const data = {
    smallTitle: 'Why CUCo?',
    title: 'CUCo for Teachers',
    titleDescription:"Through its extensive range of management features, CUCo Security Suite empowers educators to focus their resources into teaching students, by minimizing the time spent on certain tasks and facilitating them.",
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
}

export default data;