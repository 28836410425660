import React, {useEffect, useState} from "react";

import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";

import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Text from "../../atoms/text";
import Button from "../../atoms/button";



export default function Footer(props) {

    const {
        logo,
        links,
        copyright,
        socialMedia,
        images
    } = props


    const [showButton, setShowButton] = useState(false);

    const listenScrollEvent = () => {
        if (window.scrollY <= 500) {
            setShowButton(false)
        } else if (window.scrollY > 500) {
            setShowButton(true)
        } 
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);      
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    const scrollTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }


    return (
        <footer className="footer">
            <div className={`scrollTop ${showButton ? 'show' : 'hide'}`} onClick={scrollTop}>
                <Button 
                    type="fullColored bold" 
                    bgcolor="bgWhite" 
                    textcolor="textYellow" 
                    arrowRightYellow
                />
            </div>
            <div className="topRow">
                <Container size="lg">
                    {logo && logo.src &&
                        <Image src={logo.src} alt={logo.alt} />
                    }
                </Container>
            </div>
            <div className="bottomRow">
                <Container size="lg">
                    <div>
                        <div className="links-container">
                            {links && links.length > 0 && links.map((link, index) => {
                                return (
                                    link.page && link.text &&
                                    <Link to={link.page} key={index}>
                                        <Text
                                            type="span"
                                            font="poppins-regular"
                                            color="white"
                                            text={link.text}
                                            fontSize="font-16"
                                        />
                                    </Link>
                                )
                            }

                            )}
                            {copyright &&
                                <Text
                                    className="copyright"
                                    type="span"
                                    font="poppins-light"
                                    color="white"
                                    text={copyright}
                                    fontSize="font-16"
                                />
                            }
                        </div>
                        <div className="socialmedia-container">
                            {socialMedia && socialMedia.length > 0 && socialMedia.map((item, index) => {

                                return (item.link && item.image && item.image.src &&
                                    <Link key={index} to={item.link} target="_blank" rel="noopener noreferrer">
                                        <ReactSVG src={item.image.src} />
                                    </Link>
                                )
                            }

                            )}
                        </div>
                    </div>
                    <div className="images">
                        {images && images.length > 0 && images.map((item, index) => (
                            <Image key={index} src={item.src} alt={item.alt} />
                        ))}
                    </div>
                </Container>
            </div>
        </footer>
    )
}