import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";


import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import Text from "../../atoms/text";
import Menu from "../menu";
import Modal from "../modal";


export default function Header (props) {

    const {
        logo,
        menu,
        loginButton,
        requestButton,
        formModal,
        isModalOpen,
        setIsModalOpen,
        darkMode
    } = props;
    
    const [scrolled, setScrolled] = useState(false);
    const [itemActive, setItemActive] = useState(-1);
    const [menuIsOpen, setMenuIsOpen] = useState(false);


    const changeActiveItem = (index) => {
        if (itemActive === index) {
            setItemActive(-1)
        }
        else {
            setItemActive(index)
        }
    }

    const listenScrollEvent = () => {
        if (window.scrollY <= 60) {
            setScrolled(false)
        } else if (window.scrollY > 60) {
            setScrolled(true)
        } 
    }


    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);      
        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0];

        if (menuIsOpen || isModalOpen) {            
            body.classList.add("overflow-hidden");
        }
        else {
            body.classList.remove("overflow-hidden");
        }
    }, [menuIsOpen, isModalOpen]);


    return (
        <header className={`header ${darkMode ? 'darkMode' : ''} ${scrolled ? 'bg-white' : ''}`}>
            <Container size='lg'>
                <div className="content">
                    { logo && logo.src &&
                        <Link to="/">
                            <Image className="header-logo" src={logo.src} alt={logo.alt}/>
                        </Link>
                    }
                    <div className="rightSide">
                        {/* MENU DESKTOP */}
                        { menu && menu.length > 0 &&
                            <div className="dropdownLinks">
                                { menu.map((item, index) => (
                                    <div className={`header-dropdown ${itemActive === index ? 'open' : ''}`} key={index} onClick={() => changeActiveItem(index)}>
                                        {item.title &&
                                            <div className="text">
                                                <Text
                                                    type="span"
                                                    font="poppins-medium" 
                                                    color="white" 
                                                    text={item.title} 
                                                    fontSize="font-16"
                                                />
                                                <Image src="./images/arrow.svg" alt="arrow" className="arrow"/>
                                            </div>
                                        }
                                        { item.items && item.items.length > 0 &&
                                            <div className={`items ${(index !== 0  && index !== 1) ? 'simple' :''}`}>
                                                <div className="arrow-up"/>
                                                <div>
                                                {/* SIMPLE ITEM */}
                                                {index !== 0  && index !== 1 && item.items.map((link, indexLink) => (
                                                    <Link to={link.page} key={indexLink}>
                                                        {link.name &&
                                                        <Text
                                                            type="span"
                                                            font="poppins-medium" 
                                                            color="darkGrey" 
                                                            text={link.name} 
                                                            fontSize="font-16"
                                                        />
                                                        }
                                                    </Link>
                                                ))}
                                                {/* COMPLEX ITEM - 1 e 2 */}
                                                {(index === 0  || index === 1) && item.items.map((link, indexLink) => (
                                                    <Link to={link.page} key={indexLink}>
                                                        <div>
                                                            {link.name &&
                                                                <Text
                                                                    type="span"
                                                                    font="poppins-medium" 
                                                                    color="darkGrey" 
                                                                    text={link.name} 
                                                                    fontSize="font-16"
                                                                />
                                                            }
                                                             {link.description &&
                                                                <Text
                                                                    type="p"
                                                                    font="roboto-light" 
                                                                    color="darkGrey" 
                                                                    text={link.description} 
                                                                    fontSize="font-12"
                                                                />
                                                            }
                                                            {link.image && link.image.src &&
                                                            <div className="image-container">
                                                                <Image src={link.image.src} alt={link.image.alt}/>
                                                            </div>
                                                            }
                                                        </div>
                                                    </Link>
                                                ))}
                                                </div>
                                        </div>                    
                                    }                
                                </div>
                                ))}
                            </div>
                        }                    
                        { loginButton && loginButton.text &&
                            <Button type="lineColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={loginButton.text} />
                        }
                        { requestButton && requestButton.text &&
                            <Button type="fullColored" bgcolor="bgYellow" textcolor="textWhite poppins" text={requestButton.text} margin='ml16' onClick={() => setIsModalOpen(true)}/>
                        }
                        {/* MENU HAMBURGER */}
                        <div className={`hamburger ${menuIsOpen ? 'open' : ''}`} onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <span/>
                            <span/>
                        </div>
                    </div>                    
                </div>
            </Container>
            <Menu menu={menu} open={menuIsOpen}/>
            <Modal isOpen={isModalOpen} {...formModal} setIsModalOpen={setIsModalOpen}/>
        </header>
        
    )
}