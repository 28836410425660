import React from "react";

import Container from "../../atoms/container";
import Accordion from "../../molecules/accordion";


export default function FaqsList (props) {

    const {
        accordion
    } = props;

    return ( 

        <section className="faqsList margin-sectionBottom margin-sectionTop">
            <Container size="md">
                {accordion && accordion.length > 0 &&
                <div className="accordion-container">
                    { accordion.map((item, index) => (
                        <Accordion key={index} {...item}/>
                    ))}
                </div>
                }          
            </Container>
        </section>

    )
}