const data = {
    sectionTitle: 'Introducing CUCo',
    imageTitle: 'CUCo Security Suite',
    image: {
        src: './images/introducing.png',
        alt: 'CUCo'
    },
    imageLinks: {
        linkTop: "/remote-lock",
        linkLeft:"/secure-endpoint",
        linkRight: "/bios-management",
    },
    description: "CUCo Security Suite is the complete solution that guarantees devices security. The first of its kind to act at the Firmware level, CUCo Security Suite is an ever-evolving complex tool matching any projects’ security needs.",
    accordion: [
        {
            title: "Secure devices and data while reducing their theft appeal",
        },
        {
            title: "Simultaneously deploy mass updates to all devices in the network",
        },
        {
            title: "Enforce safety measures, safeguarding devices",
        },
        {
            title: "Standardize and manage the BIOS of all devices",
        }
    ]
}

export default data;