import { useState } from 'react';

import Header from '../components/organisms/header';
import LegalDetail from '../components/sections/legalDetail';
import Footer from '../components/organisms/footer';

import dataHeader from "../components/organisms/header/data";
import dataLegal from "../components/sections/legalDetail/data";
import dataFooter from "../components/organisms/footer/data"

import dataModal from "../components/organisms/modal/data";


export default function Legal() {

    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <Header {...dataHeader}
                darkMode
                formModal={dataModal}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            <LegalDetail {...dataLegal}/>
            <Footer {...dataFooter}/>
        </>
    )
}