const data = {
  banner: {
    image: {
        src:"./images/CUCoinducation.png",
        alt:"study case image",
    },
    title: 'CUCo Security Suite in Education',
    subtitle:'Safeguarding Students in the "Escola Digital"',
    category: 'Education',
  },
  /* info: [
    {
        number:'3.300',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'5.000+',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'32%',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
    {
        number:'2200',
        text:'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.'
    },
  ], */
  textArea: [
    {
      title: 'Introduction',
      text: 'In response to the increasing difitalization of education, the Portuguese Ministry of Education launched the "Escola Digital" project, aiming to provide every student in Portugal with access to a laptop. With over 1.2 million students involved, this initiative represented a substantial investment, exceeding 300 million euros, necessitating robust protection measures. Besides the financial value, the safety of students from potential theft and harm was paramount, given the attractive value of the devices and their vulnerability to exploitation.',
    },
    {
      title: 'Problem Statement',
      text: 'In response to the increasing difitalization of education, the Portuguese Ministry of Education launched the "Escola Digital" project, aiming to provide every student in Portugal with access to a laptop. With over 1.2 million students involved, this initiative represented a substantial investment, exceeding 300 million euros, necessitating robust protection measures. Besides the financial value, the safety of students from potential theft and harm was paramount, given the attractive value of the devices and their vulnerability to exploitation.',
      imageOne: {
          src:"./images/CUCoinducation1.png",
          alt:"study case image",
      },
      imageTwo: {
          src:"./images/CUCoinducation2.png",
          alt:"study case image",
      }
    },
    {
      title: 'Analysis',
      text: 'In response to the increasing difitalization of education, the Portuguese Ministry of Education launched the "Escola Digital" project, aiming to provide every student in Portugal with access to a laptop. With over 1.2 million students involved, this initiative represented a substantial investment, exceeding 300 million euros, necessitating robust protection measures. Besides the financial value, the safety of students from potential theft and harm was paramount, given the attractive value of the devices and their vulnerability to exploitation.',
      imageOne: {
          src:"./images/CUCoinducation3.png",
          alt:"study case image",
      },
    },
    {
      title: 'Conclusion',
      text: 'In response to the increasing difitalization of education, the Portuguese Ministry of Education launched the "Escola Digital" project, aiming to provide every student in Portugal with access to a laptop. With over 1.2 million students involved, this initiative represented a substantial investment, exceeding 300 million euros, necessitating robust protection measures. Besides the financial value, the safety of students from potential theft and harm was paramount, given the attractive value of the devices and their vulnerability to exploitation.',
    },
    
  ],
  moreOptions: {
    title: "",
    items: [
      {
          category:'Education',
          image: {
              src: "./images/remote_lock_thumbnail.png",
              alt: "image"
          },
          title: 'Case Study: CUCo Remote Lock Pilot Project',
          description: 'Enabling DaaS projects',
          page:'/case-studies/pilot'
      }
    ],
    button : {
      text: 'See All',
      page: '/case-studies'
    }
  }        
}


export default data;