const data = {
    categories: [
        /* {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },
        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },

        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        }, */    
    ],
    items: [
        {
            category:'2024-10-30',
            image: {
                src: "./images/thumbnail_gess.png",
                alt: "image"
            },
            title: 'CUCo Security Suite at GESS Dubai 2024',
            description: 'Join us at GESS Dubai 2024, where CUCo Firmware Security will be showcasing CUCo Security Suite from Novemeber 12th to 14th at the Dubai World Trade Center. We look forward to seeing you there!',
            page:"/news/gess"
        },
        {
            category:'2024-10-30',
            image: {
                src: "./images/thumbnail_bett.png",
                alt: "image"
            },
            title: 'CUCo Security Suite at Bett UK 2025',
            description: 'CUCo Security Suite showcased at Bett UK 2025 from the 22nd to the 24th of January.',
            page:'/news/bett'
        },

    ]

}

export default data;