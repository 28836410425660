import React from "react"


import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import Button from "../../atoms/button";
import { Link } from "react-router-dom";


export default function NewsBody( props ){

    const {
        newContent,
        button
    } = props;


    return(
        <section className={`newsBody margin-sectionBottom margin-firstSectionTop`}>
            <Container size="md">
                <div className="text-container">
                    {newContent.smallTitle &&
                        <Text 
                            type="h3"
                            font="poppins-medium"
                            fontSize="font-28"
                            color="yellow"
                            text={newContent.smallTitle}
                        />
                    }
                    <div className="title-container">
                    { newContent.title &&
                        <Text 
                            type="h1"
                            font="poppins-medium"
                            fontSize="font-71"
                            color="white"
                            text={newContent.title}
                        />
                    }
                    {newContent.date &&
                         <Text 
                            className="date"
                            type="span"
                            font="poppins-regular"
                            fontSize="font-30"
                            color="grey"
                            text={newContent.date}
                        />
                    }
                    </div>
                    { newContent.description &&
                        <Text 
                            type="p"
                            font="poppins-light"
                            fontSize="font-36"
                            color="lightGrey"
                            text={newContent.description}
                        />
                    }
                </div>
                <div className="newContent-container">
                    {newContent.image && newContent.image.src &&
                        <Image src={newContent.image.src} alt={newContent.image.alt}/>
                    }
                    <div className="newContent-text">
                        {newContent.text && newContent.text.length > 0 && newContent.text.map((item, index) => (
                            <Text 
                                key={index}
                                type="p"
                                font="roboto-light"
                                fontSize="font-24"
                                color="lightGrey"
                                text={item.paragraph}
                            />
                        ))}
                    </div>
                </div>
                
                {button && button.text && button.page &&
                    <div className="button-container">
                        <Button 
                            type="fullColored" 
                            bgcolor="bgYellow" 
                            textcolor="textBlack" 
                            text={button.text} 
                            page={button.page}
                        />
                    </div>
                }
            </Container>
        </section>

    )
}