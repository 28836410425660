const data = {
    items: [
        {
            text:"CUCo Security Suite allows teachers to restrict the content a student can access, minimizing distractions when using the devices in the classroom and ensuring that the content accessible to the students is appropriate.",
            image: {
                src:"./images/teachers_1.png",
                alt:"teachers"
            }
        }, 
        {
            text: "CUCo Security Suite enables teachers to install educational software simultaneously in all their students’ devices, streamlining the process and minimizing time spent in this task.",
            image: {
                src:"./images/teachers_2.png",
                alt:"teachers"
            }
        }, 
        {
            text: "CUCo Security Suite safeguards teachers, regarding all tasks performed through its auditing and tracking features, available for consultation via the respective back office.",
            image: {
                src:"./images/teachers_3.png",
                alt:"teachers"
            }
        }
    ]
}

export default data;