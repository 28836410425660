const data = {
    items: [
        {
            text:"CUCo Security Suite empowers administrators in the deployment of mass updates to all the devices in the network. Additionally, the schools’ IT teams are granted the ability to access a device’s BIOS, in order to troubleshoot and fix any malfunctions remotely.",
            image: {
                src:"./images/students_1.png",
                alt:"solution"
            }
        }, 
        {
            text: "CUCo Security Suite further guarantees data safety by giving school administrations the power to reset all the devices in their network, guaranteeing that data isn’t accessible upon redistribution.",
            image: {
                src:"./images/students_2.png",
                alt:"solution"
            }
        }
    ]
}

export default data;