const data = {
    smallTitle: 'Products',
    title: 'CUCo Secure Endpoint',
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "CUCo Secure Endpoint is a thorough device management system developed to optimize and secure remote management and monitoring tasks.",
    description:"Offering remote software management capabilities, CUCo Secure Endpoint allows administrators to deploy, update or remove software seamlessly from devices across the network. CUCo Secure Endpoint features endpoint localization, allowing users to manage the physical location of devices, ensuring they remain within authorized areas. Additionally, CUCo Secure Endpoint measures and limits usage parameters, offering insights into how devices are being utilized to ensure compliance and efficiency.",
    button: {
        text: 'Request Demo'
    },
    /* image: {
        src:'./images/infografia_exp.png',
        alt:'info'
    }, */
    /* infoNumbers: [
        {
            number: "3.300",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "5.000+",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "32%",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "2.200",
            text: "Lorem ipsum dolor sit amet"
        },
    ] */
}

export default data;