const data = {
    items: [
        {
            title:"Bring Your Own Device enabler",
            description:"CUCo Security Suite’s device lock, geofencing, DNS and content filtering features enable the “Bring your own Device” (BYOD) work method, by the device and its data in the case of theft and by ensuring the device is safe from malware.",
            image: {
                src:"./images/corporate_1.png",
                alt:"solution"
            }
        }, 
        {
            title:"Ensure regulatory compliance",
            description:"CUCo Security Suite’s monitoring features allows administrators to manage which software is installed in the company’s devices, remotely modify, install or uninstall them and prevent unauthorized software installation.",
            image: {
                src:"./images/corporate_2.png",
                alt:"solution"
            }
        }, 
        {
            title:"Provide peace of mind to stakeholders",
            description:"CUCo Security Suite’s many safety features safeguard the company’s investment, assets and sensitive data.",
            image: {
                src:"./images/corporate_3.png",
                alt:"solution"
            }
        }
    ],
    button: {
        text: "Case Studies",
        page:"/case-studies"
    }
}

export default data;