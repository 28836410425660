const data = {
    categories: [  
    ],
    items: [
        {
            highlight: true,
            category:'View Resource',
            image: {
                src: "./images/catalogo.png",
                alt: "catalogue"
            },
            title: 'General Catalog',
            description: '',
            file:"./files/CUCo_Catalog_GEN_EN001.pdf"
        },
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolha.png",
                alt: "image"
            },
            title: 'General Infosheet ',
            description: '',
            file:"./files/CUCo_Infosheet_GEN_EN001.pdf"
        },
        ,
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolhav3.png",
                alt: "image"
            },
            title: 'Ficha Informativa General (ES) ',
            description: '',
            file:"./files/CUCo_Infosheet_GEN_ES001.pdf"
        },
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolhav4.png",
                alt: "image"
            },
            title: 'Ficha Informativa Educacion (ES) ',
            description: '',
            file:"./files/CUCo_Infosheet_EDU_ES001.pdf"
        },
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolhav5.png",
                alt: "image"
            },
            title: 'Catálogo General (ES)',
            description: '',
            file:"./files/CUCo_Catalog_GEN_ES001.pdf"
        },
        {
            highlight: false,
            category:'View Resource',
            image: {
                src: "./images/monofolhav6.png",
                alt: "image"
            },
            title: 'Education Infosheet',
            description: '',
            file:"./files/CUCo_Infosheet_EDU_EN001.pdf"
        },

        
    ],
    /*button : {
        text: 'See more',
        page: '/'
    }*/

}

export default data;