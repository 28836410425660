import React, { useState } from "react";

import Header from "../components/organisms/header";
import Intro from "../components/sections/intro";
import CategoriesList from "../components/sections/categoriesList";
import Footer from "../components/organisms/footer";

//data
import dataHeader from "../components/organisms/header/data";
import dataIntro from "../components/sections/intro/data_catalogue";
import dataListCatalogue from "../components/sections/categoriesList/data-catalogue";
import dataFooter from "../components/organisms/footer/data";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function Catalogue(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader} 
                darkMode 
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Intro bg="white"  {...dataIntro} mb24/>
            <CategoriesList bg="white" {...dataListCatalogue}/>
            <Footer {...dataFooter}/>
        </>
    )
}