import React from "react";

import Image from "../../atoms/image";
import Text from "../../atoms/text";


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination, Autoplay } from 'swiper/modules';
import Container from "../../atoms/container";


export default function Testimonials (props) {

    const {
        slider
    } = props;

    return (
        <section className="testimonials">          
        {slider && slider.length > 0 &&
            <Swiper
                loop={true}
                autoplay={{
                    delay:8000,
                    stopOnLastSlide: false,
                   }}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Autoplay]}
            >
                { slider.map((item, index) => (
                    <SwiperSlide key={index}>
                        <Container size="lg">
                            <div>
                                {item.image && item.image.src &&
                                    <Image src={item.image.src} alt={item.image.alt}/>
                                }
                            </div>

                            <div>
                                {item.text &&
                                    <Text
                                        type="p"
                                        font="roboto-light" 
                                        color="darkGrey" 
                                        text={item.text}
                                        fontSize="font-24"      
                                    />
                                }
                                {item.author &&
                                    <Text
                                        className="author"
                                        type="span"
                                        font="roboto-bold" 
                                        color="darkGrey" 
                                        text={item.author}
                                        fontSize="font-20"      
                                    />
                                }
                                {item.position &&
                                    <Text
                                        className="position"
                                        type="span"
                                        font="roboto-light" 
                                        color="darkGrey" 
                                        text={item.position}
                                        fontSize="font-20"
                                    />
                                }
                            </div> 
                        </Container>                       
                    </SwiperSlide>
                ))}         
            </Swiper>
        }
        </section>
    );
}