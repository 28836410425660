const data = {
    title: 'Features checklist',
    leftItems: [
        {
            text: 'Built on a secure UEFI firmware foundation, ensures robust protection and reliability'
        },
        {
            text: 'All functionalities are guaranteed by the UEFI, eliminating reliance on external software'
        },
        {
            text: 'Rule-based remote lock independent of connectivity, allowing for remote locking of devices even without an internet connection'
        },
        {
            text: 'Non-destructive pre-boot lock preventing unauthorized access withou causing data loss or damage'
        },
        {
            text: 'Ensure secure and verified communication using an asynchronous, ticket-based system'
        },
        {
            text: 'Stop the operating system from loading if the device is locked'
        },
        {
            text: 'Perform automatic compliance checks to mantain security integrity'
        },
    ],
    rightItems: [
       
        {
            text: 'Support unlock recovery using a unique event code, QR code, web portal, smartphone or remote API connection'
        },
        {
            text: 'Protected against rogue BIOS reflash attempts'
        },
        {
            text: 'Compatible with multiple operating systems, including Windows, Linux and Android'
        },
        {
            text: 'Detect tampering or failures and automatically reinstalls the software agent to restore functionality'
        },
        {
            text: 'Optionally utilize TPM for secure key generation during server communication'
        },
        {
            text: 'Allow for irrevocable desdactivation of CUCo Security Suite and remote device release'
        }
    ]
}

export default data;