const data = {
    smallTitle: 'Why CUCo?',
    title: 'CUCo for Administrators',
    titleDescription:"With its remote lock functionalities, CUCo Security Suite empowers administrations, safeguarding their investments through its capabilities as a contract enforcer and its capacity to prevent theft and unauthorized resale.",
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
}

export default data;