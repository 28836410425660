const data = {
    newContent: {
        smallTitle:'News',
        title:"CUCo Security Suite at Bett UK 2025",
        description:"CUCo Security Suite showcased at Bett UK 2025 from the 22nd to the 24th of January.",
        date:"2024.05.28",
        image: {
            src:"./images/news_bett.png",
            alt:"news image",
        },
        text: [
            {
                paragraph: "CUCo Security Suite will be showcased at Bett UK 2025, the world’s leading educational exhibition, taking place in London, from the 22nd to the 24th of January. Celebrating the 40th anniversary of Bett, the 2025 UK edition promises to look back at its history and look into the future of their mission towards better global education.",
                bold: false
            },
            {
                paragraph: "At this event, CUCo Security Suite’s applicability in the educational sector will be the focus of its presentation.",
                bold: false
            },
            {
                paragraph: "Visit us at stand SH60 to find out more about how CUCo can be the solution for your educational security-related issues.",
                bold: false
            },
        ]
    }, 
    button: {
        text: "All articles",
        page: "/news"
    }   
}


export default data;