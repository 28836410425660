const data = {
    items: [
        {
            text:"CUCo Security Suite's features such as its geofencing, DNS filtering and software monitorization allow for both 'bring your own device' (BYOD) and device lending arrangements to be viable in large-scale projects.",
            image: {
                src:"./images/administrators_1.png",
                alt:"solution"
            }
        }, 
        {
            text: "CUCo Security facilitates MDM and RMM tasks by allowing for the remote update of all devices and the monitoring, managing of th devices' BIOS and grating access to it, in order to troubleshoot issues affecting the devices.",
            image: {
                src:"./images/administrators_2.png",
                alt:"solution"
            }
        }, 
        {
            text: "CUCo Security Suite grants administrators access to their organization's hardware and software inventory, allowing for the tracking of any alterations made.",
            image: {
                src:"./images/administrators_3.png",
                alt:"solution"
            }
        },
        {
            text: "CUCo Security Suite's personalized alerts, based on specific parameters, allow administrators to easily know which devices might be exposed to theft or misusage.",
            image: {
                src:"./images/administrators_4.png",
                alt:"solution"
            }
        },
        {
            text: "CUCo Security Suite audits all the tasks performed by administrators, safeguarding and tracking their actions, such as mass data wipes, remote BIOS updates, among others.",
            image: {
                src:"./images/administrators_5.png",
                alt:"solution"
            }
        }
    ]
}

export default data;