import React from "react";

import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Button from "../../atoms/button";
import Image from "../../atoms/image";
import { Link } from "react-router-dom";


export default function ListTextImage (props) {

    const {
        items,
        button,
        darkMode
    } = props

    return( 

        <section className={`listTextImage ${darkMode ? 'bgDarkGrey' : 'bgWhite margin-sectionTop'} margin-sectionBottom `}>
            <Container size="md">
                {!darkMode && items && items.length > 0 && items.map ((item, index) => (
                    <div className="item" key={index}>
                        <div className="text-container">
                            {item.title && 
                                <Text 
                                    type="h2"
                                    font="poppins-medium" 
                                    color="black"
                                    fontSize="font-36"  
                                    text={item.title} 
                                    maxLines="10"
                                />
                            }
                            {item.description &&
                                <Text 
                                    type="p"
                                    font="roboto-light" 
                                    color="lightGrey"
                                    fontSize="font-24"  
                                    text={item.description} 
                                    maxLines="20"
                                />
                            }
                            { item.button && item.button.text && item.button.page &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgDark" 
                                    textcolor="textWhite" 
                                    text={item.button.text}
                                    page={item.button.page}
                                />
                            }
                            { item.button && item.button.text && item.button.link &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgDark" 
                                    textcolor="textWhite" 
                                    text={item.button.text}
                                    link={item.button.link}
                                />
                            }
                            {item.button && item.button.text && item.button.file &&
                                <Button 
                                    type="fullColored" 
                                    bgcolor="bgDark" 
                                    textcolor="textWhite" 
                                    text={item.button.text}
                                    file={item.button.file}
                                />
                            }
                        </div>
                        <div className="image-container">
                            {item.image && item.image.src &&
                            <div>
                                <div className="image-bg"></div>
                                <div className="image">
                                    <Image src={item.image.src} alt={item.image.alt}/>
                                </div>
                            </div>
                            }
                        </div>

                    </div>
                ))}

                {!darkMode && button && button.text &&
                    <div className="button-container">
                        { button.page &&
                            <Button 
                                type="lineColored bold" 
                                bgcolor="bgBlack" 
                                textcolor="textBlack" 
                                text={button.text}
                                page={button.page}
                            />
                        }
                        { button.link &&
                            <Button 
                                type="lineColored bold" 
                                bgcolor="bgBlack" 
                                textcolor="textBlack" 
                                text={button.text}
                                link={button.link}
                            />
                        }
                        { button.file &&
                            <Button 
                                type="lineColored bold" 
                                bgcolor="bgBlack" 
                                textcolor="textBlack" 
                                text={button.text}
                                file={button.file}
                            />
                        }
                    </div>
                }

                {darkMode && items && items.length > 0 && items.map ((item, index) => (
                    <div className="item darkMode" key={index}>
                        {item.text && 
                            <Text 
                                type="p"
                                font="roboto-light" 
                                color="lightGrey"
                                fontSize="font-30"  
                                text={item.text} 
                                maxLines="10"
                            />
                        }
                        {item.image && item.image.src &&
                            <Image src={item.image.src} alt={item.image.alt}/>
                        }
                    </div>
                ))}
            </Container>
        </section>

    )
}