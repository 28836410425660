import React from "react";

import Text from "../../atoms/text";
import Image from "../../atoms/image";



export default function Checktem (props) {

    const {
        text
    } = props

    return (
        <div className="checkitem">
            <Image src="./images/checkItem.svg" alt="check"/>
            { text && 
                <Text 
                    type="span"
                    font="roboto-light" 
                    color="lightGrey"
                    fontSize="font-24" 
                    text={text}
                    maxLines='20'
                />
            }
        </div>
    )
}
