const data = {
    smallTitle: 'Solutions',
    title: 'CUCo in the Corporate sector',
    bgImage: {
        src: './images/corporate_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "Applicability in corporate environments",
    description:"In today's corporate landscape, safeguarding sensitive business data and confidential information is paramount. CUCo Security Suite delivers an additional layer of protection, securing devices against threats such as malware attacks and unauthorized device manipulation attempts.",
}

export default data;