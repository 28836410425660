const data = {
    items: [
        {
            text: "CUCo Security Suite simplifies device protection and monitoring in large-scale projects, cutting costs and maximizing investments and their return.",
            image: {
                src:"./images/integrators_1.png",
                alt:"solution"
            }
        }, 
        {
            text: "CUCo Security Suite matches many of the requirements needed for various implementation projects and contests, fortifying any applications that include it.",
            image: {
                src:"./images/integrators_2.png",
                alt:"solution"
            }
        },
    ]
}

export default data;