const data = [
    {
        title: 'Mobilizing Schedules/Alliances for the re-industrialization:',
        text: 'BLOCKCHAIN.PT – Schedule “Descentralizar Portugal com Blockchain”'
    },
    {
        title: 'Consortium Leader:',
        text: 'VOID SOFTWARE, S.A'
    },
    {
        title: 'Main goal: ',
        text: 'The Schedule “Descentralizar Portugal com Blockchain” aims to create a national blockchain ecosystem, recognizing the importance of technology as an innovation driver and aiming to capitalize on global business opportunities generated by this'
    },
    {
        title: 'Beneficiary entity:',
        text: 'Softi9 - Inovação Informática, Lda.'
    },
    {
        title: 'Start date: ',
        text: '01/01/2023'
    },
    {
        title: 'End date: ',
        text: '12/31/2025'
    },
    {
        title: 'Total Investment: ',
        text: '€58,854,715.56'
    },
    {
        title: 'Total Incentive:',
        text: '€1,071,995.61'
    },
    {
        title: 'Objectives:',
        text: 'The ecosystem formed in this Schedule will enable the training of qualified professionals in this field, strengthen the national blockchain industry, enhance the credibility of the national blockchain industry, and help transform Portugal into a global leader in this technology'
    },
    {
        title:"Expected Results: "
    },
    {
        bullets:[
            {
                text:"PPS01 - Traceability IoT Device for Cattle"
            },
            {
                text:"PPS02 - Livestock’s traceability solution "
            },
            {
                text:"PPS03 - Flexible Sensor for Agri-food"
            },
            {
                text:"PPS04 - Food supply-chain solution"
            },
            {
                text:"PPS05 - MyHR platform"
            },
            {
                text:"PPS06 - BioGHP Platform"
            },
            {
                text:"PPS07 - Greencoin Smart City Platform"
            },
            {
                text:"PPS08 - Blockchain in a box"
            },
            {
                text:"PPS09 - Carbon Emissions Platform"
            },
            {
                text:"PPS10 - QUDO Platform extension"
            },
            {
                text:"PPS11 - Blockchain Gaming Platform "
            },
            {
                text:"PPS12 - Blockchain-based social network for athletes"
            },
            {
                text:"PPS13 - Loyalty Platform"
            },
            {
                text:"PPS14 - DeFI for merchants"
            },
            {
                text:"PPS15 - Innovation and Talent Platform"
            },
            {
                text:"PPS16 - Device-as-a-Service platform"
            },
            {
                text:"PPS17 - Loans' Management Platform"
            },
            {
                text:"PPS18 - NexGen Platform"
            },
            {
                text:"PPS19 - Automated Smart Contracts Protocol"
            },
            {
                text:"PPS20 - Decentralised Real Estate Digital Assets Platform"
            },
            {
                text:"PPS21 - Smart Rent Solution"
            },
            {
                text:"PPS22 - Portuguese Digital Wallet"
            },
            {
                text:"PPS23 - Interoperabilty projects"
            },
            {
                text:"PPS24 - Blueprints for Digital Wallets"
            },
            {
                text:"PPS25 - Clube de Produtores Continente"
            },
            {
                text:"PPS26 - Blockchain Training Courses"
            },
        ]
    }

]

export default data;