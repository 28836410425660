const data = {
    smallTitle: 'Solutions',
    title: 'CUCo in Governmental Agencies',
    bgImage: {
        src: './images/government_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "Applicability in governmental projects",
    description:"Governmental agencies face many challenges regarding the security of their devices and data, as often, large teams of employees manage sensitive information, leading to concerns about information leaks.",
}

export default data;