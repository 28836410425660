const data = {
    accordion: [
        {
            title: "How does CUCo Security Suite deter theft?",
            description: "CUCo Security Suite acts as a theft deterrent by rendering the device useless for thieves through its remote lock feature, making it invaluable for sale in the black market and restricting access."
        },
        {
            title: "How does CUCo Security Suite utilize Blockchain technology?",
            description: "CUCo Security Suite employs smart contracts, which integrates blockchain technology to automatically enforce contracts by both sides, without the need for a regulatory third party."
        },
        {
            title: "What happens if the operating system is reinstalled or the hard drive is replaced?",
            description: "CUCo Security Suite is tied to the firmware, thus its security features are resistant to attempts to override the remote lock through these methods."
        
        },
        {
            title: "Can I use CUCo Security Suite with other device management platforms?",
            description: "Yes, CUCo Security Suite incorporates a robust API, allowing for flexibility and easy integration with other device management platforms."
        
        },
        {
            title: "Is my data and privacy secure with CUCo Security Suite?",
            description: "CUCo Security Suite follows GRPD regulations, guaranteeing the users’ data safety and their privacy."
        
        },
        {
            title: "Can you simultaneously manage devices from different companies?",
            description: "Yes, CUCo Security Suite is compatible with most major companies and allows for the simultaneous management of all the devices through one single platform."
        
        },
        {
            title: "Can you customize security permissions for different profiles?",
            description: "Yes. CUCo Security Suite allows for different levels of permissions through premade permission levels or individual customizable parameters."
        
        }
    ]
}

export default data;