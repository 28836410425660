import React, {useState} from "react";

import Text from "../../atoms/text";
import Image from "../../atoms/image";



export default function AccordionSection (props) {

    const [active, setActive] = useState(false);

    const changeState = () => {
        setActive(!active);
    }

    const {
        title,
        description
    } = props;

    return (
        title && description ?
            <div className={`accordion-box ${active ? 'open' : 'closed'}`} onClick={changeState}>
                <div className="title-container">
                    <Text 
                        type="h3"
                        font="poppins-regular"
                        fontSize="font-24"
                        color="yellow"
                        text={title}
                        maxLines="10"
                    />
                    <div className="button" onClick={changeState}><Image src="/images/accordion-button.svg" alt="button"/></div>
                </div>
                <div className="description-container">
                    <Text 
                        type="p"
                        font="roboto-light"
                        fontSize="font-20"
                        color="white"
                        text={description}
                        maxLines="10"
                    />
                </div>
            </div>
        : 
        <div className={`accordion-box simple`}>
            <div className="title-container full">
                <Text 
                    type="h3"
                    font="poppins-regular"
                    fontSize="font-24"
                    color="white"
                    text={title ? title : ''}
                    maxLines="10"
                />
            </div>
        </div>
    )
}
