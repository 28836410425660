const data = {
    smallTitle: 'Why CUCo?',
    title: 'CUCo for Telcos',
    titleDescription:"CUCo Security Suite is the enabler of the Device as a Service (DaaS) business model. Its ability to enforce contracts and lock device access when contracts are breached, diminishes auditory and management costs, while lessening the risk of non-compliance.",
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
}

export default data;