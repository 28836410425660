import React from "react";

import Text from "../../atoms/text";
import Container from "../../atoms/container";


export default function ProjectDetail (props) {

    const {
        detail
    } = props;


    return ( 

        <section className="projectDetail margin-sectionBottom margin-sectionTop">
            <Container size="md">
             { detail && detail.length > 0 && detail.map((item, index) => (
                item.bullets ?
                item.bullets && item.bullets.length > 0 &&
                    <ul>
                        {item.bullets.map((bullet, bIndex) => (
                            <li key={bIndex}>
                                <Text 
                                    type="span"
                                    font="roboto-light"
                                    fontSize="font-24"
                                    color="black"
                                    text={bullet?.text}
                                />  
                            </li>                                        
                        ))}
                    </ul>
                    :                    
                    <p key={index}>
                    <Text 
                        type="span"
                        font="roboto-medium"
                        fontSize="font-32"
                        color="black"
                        text={item?.title}
                    />
                    <Text 
                        type="span"
                        font="roboto-light"
                        fontSize="font-32"
                        color="black"
                        text={item?.text}
                    />

                </p>
             ))
             }
             <a href="www.recuperarportugal.gov.pt" target="_blank">www.recuperarportugal.gov.pt</a>
            </Container>
        </section>

    )
}