import React from "react";


import Container from "../../atoms/container";
import Text from "../../atoms/text";
import Checktem from "../../molecules/checkItem";


export default function Checklist (props) {

    const {
        title,
        leftItems,
        rightItems
    } = props;

    return ( 

        <section className="checklist margin-sectionBottom margin-sectionTop bgWhite">
            <Container size="md">
            
                { title &&
                    <Text 
                        type="h3"
                        font="poppins-medium"
                        fontSize="font-36"
                        color="yellow"
                        text={title}
                        maxLines="2"
                    />
                }

                <div className="list-container">
                    {leftItems && leftItems.length > 0 &&
                        <div>
                            {leftItems.map ((item, index) => (
                                <Checktem key={index} {...item}/>
                            ))}
                        </div> 
                    }
                        
                    {rightItems && rightItems.length > 0 &&
                    <div>
                        {rightItems.map ((item, index) => (
                            <Checktem key={index} {...item}/>
                        ))}
                    </div> 
                    }
                </div>
                
            </Container>
        </section>

    )
}