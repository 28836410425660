import React, { useState } from "react";

import Header from "../components/organisms/header";
import Intro from "../components/sections/intro";
import ProjectDetail from "../components/sections/projectDetail";
import Footer from "../components/organisms/footer";

//data
import dataHeader from "../components/organisms/header/data";
import dataIntro from "../components/sections/intro/data_projects";
import dataDetail from "../components/sections/projectDetail/data";
import dataFooter from "../components/organisms/footer/data";


// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";


export default function Projects(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                darkMode
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Intro bg="white" {...dataIntro}/>
            <ProjectDetail detail={dataDetail}/>
            <Footer {...dataFooter}/>
        </>
    )
}