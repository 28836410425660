import React from "react";
//import { Link } from "react-router-dom";


import Text from "../../atoms/text";
import Container from "../../atoms/container";
import Image from "../../atoms/image";
import StatisticsBox from "../../molecules/statisticsBox";



export default function CaseStudyBody( props ){

    const {
        banner,
        info
    } = props;



    return (
        <section className='caseStudyBanner'>
            <div className="banner">
                { banner.image && banner.image.src &&
                    <Image src={banner.image.src} alt={banner.image.alt}/>
                }
                <div className="darkerBackground"></div>
                <Container size="md">
                    <div className="text-container margin-firstSectionTop margin-sectionBottom">
                        {banner.title &&
                            <Text 
                                type="h1"
                                font="poppins-medium"
                                fontSize="font-71"
                                color="white"
                                text={banner.title}
                                maxLines="2"
                            />
                        }
                        {banner.subtitle &&
                            <Text 
                                type="h2"
                                font="poppins-extralight"
                                fontSize="font-71"
                                color="white"
                                text={banner.subtitle}
                                maxLines="3"
                            />
                        }

                        {banner.category &&
                            <Text 
                                className="category"
                                type="span"
                                font="poppins-regular"
                                fontSize="font-30"
                                color="white"
                                text={banner.category}
                            />
                        }       
                    </div>
                </Container>
            </div> 
            {info && info.length > 0 &&
                <div className="info-container">
                    <Container size="md">
                    { info.map ((item, index) => (
                        <StatisticsBox key={index} {...item}/>
                    ))}
                    </Container>
                </div>
            }           
        </section>

    )
}