const data = {
    logo: {
        src: './images/logo-small.svg',
        alt: 'Cuco'
    },
    menu: [
        {
            title: 'Products',
            items: [
                {
                    name: 'Remote Lock',
                    description: 'Lock your device remotely, through pre-defined parameters, ensuring the protection of your assets',
                     image: {
                        src: './images/remote_lock.svg'
                    },
                    page: '/remote-lock'
                },
                {
                    name: 'Secure Endpoint',
                    description: 'CUCo Secure Endpoint offers geo-localization services, RMMs and MDMs, allowing for the management of large hardware parks.',
                     image: {
                        src: './images/secure_endpoint.svg'
                    },
                    page: '/secure-endpoint'
                },
                {
                    name: 'Remote BIOS Management',
                    description: 'Reduce worload, ensure BIOS-level security and UEFI mass management all in a user-friendly UI.',
                     image: {
                        src: './images/remote_bios.svg'
                    },
                    page: '/bios-management'
                },
            ]
        },
        {
            title: 'Solutions',
            items: [
                {
                    name: 'Education',
                    description: 'From remote management features to device locking tools, CUCo Security Suite safeguards students and school administrators.',
                     image: {
                        src: './images/educational.svg'
                    },
                    page: '/education'
                },
                {
                    name: 'Corporate',
                    description: 'CUCo Secure Suite grants you the power to manage your company`s software and hardware park, while protecting your investments.',
                    image: {
                        src: './images/corporate.svg'
                    },
                    page: '/corporate'
                },
                {
                    name: 'Governmental',
                    description: 'CUCo Security empowers governmental agencies by securing sensitive data, auditing and optimizing administrative tasks.',
                    image: {
                        src: './images/governmental.svg'
                    },
                    page: '/government'
                }             
            ]
        },
        {
            title: 'Resources',
            items: [
                {
                    name:'Case Studies',
                    page: '/case-studies'
                },
                {
                    name:'News',
                    page: '/news'
                },
                {
                    name:'Catalogue',
                    page:'/catalogue'
                }
            ]
        },
        /* {
            title: 'Partners',
            items: [
                {
                    name:'item 1',
                    page: '/'
                },
                {
                    name:'item 1',
                    page: '/'
                },
                {
                    name:'item 1',
                    page: '/'
                },
                {
                    name:'item 1',
                    page: '/'
                },

            ]
        }, */
        {
            title: 'Why CUCo?',
            items: [
                {
                    name:'for Administrators',
                    page: '/why-cuco/admin'
                },
                {
                    name:'for OEM & ODM',
                    page: '/why-cuco/oem'
                },
                {
                    name:'for Students',
                    page: '/why-cuco/students'
                },
                {
                    name:'for Teachers',
                    page: '/why-cuco/teachers'
                },
                {
                    name:'for Telcos',
                    page:'/why-cuco/telecom'
                }

            ]
        },
        {
            title: 'Support',
            items: [
                {
                    name:'FAQs',
                    page: '/faqs'
                },
                /* {
                    name:'item 1',
                    page: '/'
                },
                {
                    name:'item 1',
                    page: '/'
                },
                {
                    name:'item 1',
                    page: '/'
                }, */
                
            ]
        }

    ],
    /* loginButton: {
        text: 'Login'
    }, */
    requestButton: {
        text: 'Request Demo',
    }


}

export default data;