const data = {
    items: [
        {
            text:"CUCo Security Suite's safe-internet features allow telecom providers to broaden their target audience, by protecting those who are more vulnerable online.",
            image: {
                src:"./images/telecommunications_1.png",
                alt:"telcos"
            }
        }, 
        {
            text: "	CUCo Security Suite parental control features can also be integrated in family telecommunication plans, empowering parents to manage what contents their kids have access to.",
            image: {
                src:"./images/telecommunications_2.png",
                alt:"telcos"
            }
        },
        
    ]
}

export default data;