import React from "react";


export default function Image (props) {

    const {
        src ="",
        alt ="",
        className = ""
    } = props;


    return (
        <img className={className} src={src} alt={alt}/>
    )
}