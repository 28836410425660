const data = {
    smallTitle: 'Products',
    title: 'CUCo Remote BIOS Management',
    bgImage: {
        src: './images/product_header.png',
        alt: 'CUCo'
    },
    descriptionBold: "CUCo Remote BIOS Management is an innovative BIOS management solution constructed with the goal of making BIOS configuration and security accessible at every level.",
    description:"CUCo Remote BIOS Management allows administrators to remotely manage and secure the BIOS/UEFI settings on their devices, through a streamlined and user-friendly platform. Amongst other features this module includes remote BIOS configurations updates and PXE boot switch toggling, supporting organizations in the deployment of mass firmware updates and granting administrators control over network boot settings, enhancing security and efficiently reducing workload.",
    button: {
        text: 'Request Demo'
    },
    /* image: {
        src:'./images/infografia_exp.png',
        alt:'info'
    }, */
    /* infoNumbers: [
        {
            number: "3.300",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "5.000+",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "32%",
            text: "Lorem ipsum dolor sit amet"
        },
        {
            number: "2.200",
            text: "Lorem ipsum dolor sit amet"
        },
    ] */
}

export default data;