import React, { useState } from "react";

import Header from "../components/organisms/header";
import Banner from "../components/sections/banner";
import Partners from "../components/sections/partners";
import Products from "../components/sections/products";
import Introducing from "../components/sections/introducing";
import Testimonials from "../components/sections/testimonials";
import Footer from "../components/organisms/footer";

//data
import dataHeader from "../components/organisms/header/data";
import dataBanner from "../components/sections/banner/data";
import dataPartners from "../components/sections/partners/data";
import dataProducts from "../components/sections/products/data";
import dataIntroducing from "../components/sections/introducing/data";
import dataTestimonials from "../components/sections/testimonials/data";
import dataFooter from "../components/organisms/footer/data";

// REQUEST DEMO MODAL
import dataModal from "../components/organisms/modal/data";

export default function Homepage(){

    const [isModalOpen, setIsModalOpen] = useState(false);

    return(
        <>
            <Header 
                {...dataHeader}  
                formModal={dataModal} 
                isModalOpen={isModalOpen} 
                setIsModalOpen={setIsModalOpen}
            />
            <Banner {...dataBanner}/>
            {/*<Partners {...dataPartners}/>*/}
            <Products {...dataProducts}/>
            <Introducing {...dataIntroducing}/>
            <Testimonials {...dataTestimonials}/>
            <Footer {...dataFooter}/>
        </>
    )
}